import axios from "axios";

const ccpaformsubmit = async (requestbody) => {
  try {
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      data: requestbody,
      url: `/api/v1/email/send`,
    };
    
    const response = await axios(options);

    const { data } = response;
    

    return {
      status: response.status,
      message: data,
    };
  } catch (e) {
    return new Error(e);
  }
};

export default ccpaformsubmit;

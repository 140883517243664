import React, { useContext } from 'react'
import { Button } from '@material-ui/core';
import arrow from '../static/arrow.png';
import { ClassesContext } from './StylesProvider';

type Props = {
    label?: string,
    className?: string,
    handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}

const CheckYourRate = ({ label, className, handleClick }: Props) => {
    const themedClasses = useContext(ClassesContext);

    return <Button
        className={themedClasses.checkYourRateButton}
        variant="contained"
        endIcon={<img className={themedClasses.checkYourRateArrow} src={arrow} alt="Get results arrow." />}
        onClick={handleClick}
    >
        Check Your Rate
    </Button>
}

export default CheckYourRate
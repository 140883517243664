import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import circleNumber1 from '../static/home/1_logo.png';
import circleNumber2 from '../static/home/2_logo.png';
import circleNumber3 from '../static/home/3_logo.png';

const useStyles = makeStyles(theme => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    body: {
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '31px',
            paddingBottom: '30px',
        },
        [theme.breakpoints.only('md')]: {
            paddingTop: '50px',
            paddingBottom: '43px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingBottom: '100px',
            paddingTop: '50px',
            maxWidth: '1170px',
        },
    },
    header: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#2b6587',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
            textAlign: 'center',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '30.5px',
            textAlign: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '50px',
            textAlign: 'center',
        },
    },
    circlesWrapper: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
            flexDirection: 'column',
        },
        [theme.breakpoints.only('md')]: {
            justifyContent: "center",
            flexDirection: 'row',
        },
        [theme.breakpoints.up('lg')]: {
            justifyContent: "center",
            flexDirection: 'row',
        },
    },
    circleGroup: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    circles: {
        [theme.breakpoints.down('sm')]: {
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '41px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '59px',
        },
    },
    circleImage: {
        alignSelf: 'center',
        backgroundColor: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
            width: '42px',
            height: '41px',
        },
        [theme.breakpoints.only('md')]: {
            width: '40px',
            height: '39px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '40px',
            height: '39px',
        },
    },
    circleHeader: {
        alignSelf: 'center',
        fontWeight: 'bold',
        fontFamily: 'OpenSans, Sans-serif',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#157ebc',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            marginTop: '16px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '15px',
            fontSize: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '24px',
            marginTop: '28px',
        },
    },
    circleText: {
        alignSelf: 'center',
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 300,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            marginRight: '33px',
            marginLeft: '33px',
            marginTop: '13px',
            fontSize: '16px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '15px',
            maxWidth: '80%',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '21px',
            lineHeight: '1.52',
            marginTop: '29px',
        },
    },
}));

const HowItWorks = () => {
    const classes = useStyles();

    return <div className={classes.responsiveWrapper}>
        <div className={classes.body}>
            <div className={classes.header}>How It Works</div>
            <div className={classes.circlesWrapper + ' ' + classes.circles}>
                <div className={classes.circleGroup}>
                    <img src={circleNumber1} className={classes.circleImage} alt="Number 1" />
                    <div className={classes.circleHeader}>
                        TELL US ABOUT YOUR DEBT
                    </div>
                    <div className={classes.circleText}>
                        Submit your debt amount and contact info online or give us a call.
                    </div>
                </div>
                <div className={classes.circleGroup}>
                    <img src={circleNumber2} className={classes.circleImage} alt="Number 1" />
                    <div className={classes.circleHeader}>
                        REVIEW YOUR OPTIONS
                    </div>
                    <div className={classes.circleText}>
                        We will review your finances and give you the best option.
                    </div>
                </div>
                <div className={classes.circleGroup}>
                    <img src={circleNumber3} className={classes.circleImage} alt="Number 1" />
                    <div className={classes.circleHeader}>
                        GET A CLEAR PATH FORWARD
                    </div>
                    <div className={classes.circleText}>
                        Accept your offer and begin saving money for your dreams!
                    </div>
                </div>
            </div>
        </div >
    </div >
}

export default HowItWorks;

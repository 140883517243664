import React, { useContext, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Container } from "@material-ui/core";
import Faqs from "../components/Faqs";
import DoYouQualifyInline from "../components/DoYouQualifyInline";
import { StylesContext } from "../components/StylesProvider";
import Header from "../components/Header";
import Footer from "../components/Footer";
import defaultData from "../defaultData.js";

const FaqPage = ({ match }: RouteComponentProps<any>) => {
  const setThemeName = useContext(StylesContext);
  setThemeName("faqs");
  const [debitAmount, setDebitAmount] = useState("");

  return (
    <React.Fragment>
      <Container
        maxWidth={false}
        disableGutters
        style={{ backgroundColor: "#ffffff" }}
      >
        <Header
          phoneNumber={defaultData.phoneNumber}
          showPhone={true}
          showWhyChooseUs={true}
          showFaqs={true}
          showCheckRates={true}
        />
        <Faqs />
        <DoYouQualifyInline
          debitAmount={debitAmount}
          setDebitAmount={setDebitAmount}
        />
        <Footer
          addressLine1={defaultData.addressLine1}
          addressLine2={defaultData.addressLine2}
          copyright={defaultData.copyright}
          email={defaultData.email}
        />
      </Container>
    </React.Fragment>
  );
};

export default FaqPage;

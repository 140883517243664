import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import circleNumber1 from '../static/home/1_logo.png';
import circleNumber2 from '../static/home/2_logo.png';
import circleNumber3 from '../static/home/3_logo.png';
import { Grid, Paper, useMediaQuery, useTheme } from '@material-ui/core';

import image1 from '../static/input_debt_icon.png';
import image2 from '../static/review_your_debt_icon.png';
import image3 from '../static/begin_journey_icon.png';
import HowItWorks from './HowItWorks';

const useStyles = makeStyles(theme => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    body: {
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 0 10px',
        borderRadius: '6px',
        marginTop: '70px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '31px',
            paddingBottom: '30px',
        },
        [theme.breakpoints.only('md')]: {
            paddingTop: '50px',
            paddingBottom: '43px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '45px',
            paddingRight: '45px',
            paddingBottom: '100px',
            paddingTop: '50px',
            maxWidth: '1170px',
        },
    },
    header: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#2b6587',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
            textAlign: 'center',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '30.5px',
            textAlign: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '50px',
            textAlign: 'center',
        },
    },
    circlesWrapper: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
            flexDirection: 'column',
        },
        [theme.breakpoints.only('md')]: {
            justifyContent: "center",
            flexDirection: 'row',
        },
        [theme.breakpoints.up('lg')]: {
            justifyContent: "center",
            flexDirection: 'row',
        },
    },
    circleGroup: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    circles: {
        [theme.breakpoints.down('sm')]: {
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '41px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '59px',
        },
    },
    circleImage: {
        alignSelf: 'center',
        backgroundColor: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
            width: '42px',
            height: '41px',
        },
        [theme.breakpoints.only('md')]: {
            width: '40px',
            height: '39px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '70px',
            height: '68px',
        },
    },
    depiction1: {
        alignSelf: 'center',
        backgroundColor: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
        },
        [theme.breakpoints.only('md')]: {
            maxHeight: '85px',
            maxWidth: '91px',
        },
        [theme.breakpoints.up('lg')]: {
        },
    },
    depiction2: {
        alignSelf: 'center',
        backgroundColor: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
        },
        [theme.breakpoints.only('md')]: {
            maxHeight: '79px',
            maxWidth: '89px',
        },
        [theme.breakpoints.up('lg')]: {
        },
    },
    depiction3: {
        alignSelf: 'center',
        backgroundColor: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
        },
        [theme.breakpoints.only('md')]: {
            maxHeight: '69px',
            maxWidth: '103px',
        },
        [theme.breakpoints.up('lg')]: {
        },
    },
    circleHeader: {
        alignSelf: 'center',
        fontWeight: 'bold',
        fontFamily: 'OpenSans, Sans-serif',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#157ebc',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            marginTop: '16px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '15px',
            fontSize: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '24px',
            marginTop: '15px',
        },
    },
    circleText: {
        alignSelf: 'center',
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 300,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            marginRight: '33px',
            marginLeft: '33px',
            marginTop: '13px',
            fontSize: '16px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '15px',
            maxWidth: '80%',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '21px',
            lineHeight: '1.52',
            marginTop: '15px',
        },
    },
    root: {
        flexGrow: 1,
        marginTop: '55px',
    },
    paper: {
        padding: '5px',
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const HowItWorksImages = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (isMobile) {
        return <HowItWorks />
    }
    
    return <div className={classes.responsiveWrapper}>
        <div className={classes.body}>
            <div className={classes.header}>How It Works</div>
            <div className={classes.root}>
                <Grid container spacing={0}>
                    <Grid item xs={4}>
                        <Paper elevation={0} className={classes.paper}>
                            <img src={circleNumber1} className={classes.circleImage} alt="Number 1" />
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={0} className={classes.paper}>
                            <img src={circleNumber2} className={classes.circleImage} alt="Number 2" />
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={0} className={classes.paper}>
                            <img src={circleNumber3} className={classes.circleImage} alt="Number 3" />
                        </Paper>
                    </Grid>

                    <Grid item xs={4}>
                        <Paper elevation={0} className={classes.paper}>
                            <img src={image1} className={classes.depiction1} alt="Depiction Number 1" />
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={0} className={classes.paper}>
                            <img src={image2} className={classes.depiction2} alt="Depiction Number 2" />
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={0} className={classes.paper}>
                            <img src={image3} className={classes.depiction3} alt="Depiction Number 3" />
                        </Paper>
                    </Grid>

                    <Grid item xs={4}>
                        <Paper elevation={0} className={classes.paper}>
                            <div className={classes.circleHeader}>
                                INPUT YOUR DEBT
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={0} className={classes.paper}>
                            <div className={classes.circleHeader}>
                                REVIEW YOUR OPTIONS
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={0} className={classes.paper}>
                            <div className={classes.circleHeader}>
                                BEGIN YOUR JOURNEY
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={4}>
                        <Paper elevation={0} className={classes.paper}>
                            <div className={classes.circleText}>
                                Submit your debt amount and contact info online or give us a call.
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={0} className={classes.paper}>
                            <div className={classes.circleText}>
                                We will review your finances and give you the best option.
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={0} className={classes.paper}>
                            <div className={classes.circleText}>
                                Accept your offer and begin saving money for your dreams!
                            </div>
                        </Paper>
                    </Grid>

                </Grid>
            </div>
        </div >
    </div >
}

export default HowItWorksImages;

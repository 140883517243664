import React, { useContext } from "react";
import { Container } from "@material-ui/core";
import AccreditedBar from "../components/AccreditedBar";
import HowItWorks from "../components/HowItWorks";
import IsDebtConsolodationRightForMe from "../components/IsDebtConsolodationRightForMe";
import Benefits from "../components/Benefits";
import HowMuchDoYouNeed from "../components/HowMuchDoYouNeed";
import { makeStyles } from "@material-ui/core";
import { StylesContext } from "../components/StylesProvider";
import Header from "../components/Header";
import Footer from "../components/Footer";
import defaultData from "../defaultData.js";

const useStyles = makeStyles((theme) => ({
  horizontalLine: {
    height: "2px",
    width: "100%",
    maxWidth: "1170px",
  },
}));

const Homepage: React.FC = () => {
  const setThemeName = useContext(StylesContext);
  setThemeName("landing");

  const classes = useStyles();

  return (
    <React.Fragment>
      <Container
        maxWidth={false}
        disableGutters
        style={{ backgroundColor: "#ffffff" }}
      >
        <Header
          phoneNumber={defaultData.phoneNumber}
          showPhone={true}
          showWhyChooseUs={true}
          showFaqs={true}
          showCheckRates={true}
        />
        <HowMuchDoYouNeed />
        <AccreditedBar />
        <hr className={classes.horizontalLine} />
        <HowItWorks />
        <IsDebtConsolodationRightForMe />
        <Benefits />
        <Footer
          addressLine1={defaultData.addressLine1}
          addressLine2={defaultData.addressLine2}
          copyright={defaultData.copyright}
          email={defaultData.email}
        />
      </Container>
    </React.Fragment>
  );
};

export default Homepage;

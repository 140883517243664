import React, { useContext, useState } from "react";
import validator from "validator";
import { Container } from "@material-ui/core";

import { RouteComponentProps } from "react-router-dom";
import { StylesContext } from "../components/StylesProvider";

import Header from "../components/Header";
import Footer from "../components/Footer";
import defaultData from "../defaultData.js";
import CCPARequestFormPage from "../components/CCPARequestFormPage";
import ccpaFormSubmit from "../api/ccpaformsubmit.js";

export type FormErrors = {
  firstName: boolean;
  lastName: boolean;
  phone: boolean;
  email: boolean;
  address: boolean;
  city: boolean;
  addressState: boolean;
  zipcode: boolean;
  dob: boolean;
};

const CCPARequestForm = ({ match }: RouteComponentProps<any>) => {
  const setThemeName = useContext(StylesContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [addressState, setStateAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [dob, setDOB] = useState("");
  const [details, setAddDetails] = useState("");
  const [checkedDelete, setCheckedDelete] = useState("");
  const [checkedContactPref, setCheckedContactPref] = useState("");
  const [checkedDelivery, setCheckedDelivery] = useState("");
  const [checkedExclude, setCheckedExclude] = useState("");
  const [checkedSendPersonal, setCheckedSendPersonal] = useState("");
  const [sentSuccess, setSentSuccess] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    address: false,
    city: false,
    addressState: false,
    zipcode: false,
    dob: false,
  });

  const submitForm = async (body: string) => {
    const submitResponse = await ccpaFormSubmit(body);

    // @ts-ignore
    if (submitResponse.status === 200) {
      window.scrollTo(0, 0);
      setShowLoading(false);
      setSentSuccess(true);
      setShowForm(false);

      console.log("email sent");
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      var body = JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
        email: email,
        address: address,
        city: city,
        addressState: addressState,
        zipcode: zipcode,
        dob: dob,
        details: details ? details : "",
        response1: checkedDelivery,
        response2: checkedSendPersonal,
        response3: checkedExclude,
        response4: checkedDelete,
        response5: checkedContactPref,
      });
      setShowLoading(true);
      submitForm(body);
    }
  };

  const validate = () => {
    let errorFound = false;
    const errors: FormErrors = {
      firstName: false,
      lastName: false,
      phone: false,
      email: false,
      address: false,
      city: false,
      addressState: false,
      zipcode: false,
      dob: false,
    };

    if (firstName === "") {
      errors.firstName = true;
      errorFound = true;
    }

    if (lastName === "") {
      errors.lastName = true;
      errorFound = true;
    }

    if (!validator.isMobilePhone(phone, "en-US")) {
      errors.phone = true;
      errorFound = true;
    }

    if (!validator.isEmail(email)) {
      errors.email = true;
      errorFound = true;
    }
    if (address === "") {
      errors.address = true;
      errorFound = true;
    }
    if (addressState === "") {
      errors.addressState = true;
      errorFound = true;
    }
    if (city === "") {
      errors.city = true;
      errorFound = true;
    }
    if (zipcode === "") {
      errors.zipcode = true;
      errorFound = true;
    }
    if (dob === "") {
      errors.dob = true;
      errorFound = true;
    }
    if (checkedExclude === "") {
      errorFound = true;
    }
    if (checkedDelivery === "") {
      errorFound = true;
    }
    if (checkedContactPref === "") {
      errorFound = true;
    }
    if (checkedDelete === "") {
      errorFound = true;
    }
    if (checkedSendPersonal === "") {
      errorFound = true;
    }

    setFormErrors(errors);

    return !errorFound;
  };

  return (
    <React.Fragment>
      <Container
      className="containerRoot"
        maxWidth={false}
        disableGutters
        style={{ backgroundColor: "#ffffff" }}
      >
        <Header
          phoneNumber={defaultData.phoneNumber}
          showPhone={true}
          showWhyChooseUs={true}
          showFaqs={true}
          showCheckRates={true}
        />
        <CCPARequestFormPage
          firstName={firstName}
          lastName={lastName}
          phone={phone}
          email={email}
          address={address}
          city={city}
          addressState={addressState}
          zipcode={zipcode}
          dob={dob}
          details={details}
          showForm={showForm}
          sentSuccess={sentSuccess}
          checkedDelete={checkedDelete}
          checkedContactPref={checkedContactPref}
          checkedDelivery={checkedDelivery}
          checkedExclude={checkedExclude}
          checkedSendPersonal={checkedSendPersonal}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setAddress={setAddress}
          setCity={setCity}
          setStateAddress={setStateAddress}
          setDOB={setDOB}
          setZipcode={setZipcode}
          setPhone={setPhone}
          setEmail={setEmail}
          setAddDetails={setAddDetails}
          setCheckedDelete={setCheckedDelete}
          setCheckedContactPref={setCheckedContactPref}
          setCheckedDelivery={setCheckedDelivery}
          setCheckedExclude={setCheckedExclude}
          setCheckedSendPersonal={setCheckedSendPersonal}
          setSentSuccess={setSentSuccess}
          handleSubmit={handleSubmit}
          setShowForm={setShowForm}
          showLoading={showLoading}
          setShowLoading={setShowLoading}
          formErrors={formErrors}
        />
        <Footer
          addressLine1={defaultData.addressLine1}
          addressLine2={defaultData.addressLine2}
          copyright={defaultData.copyright}
          email={defaultData.email}
        />
      </Container>
    </React.Fragment>
  );
};

export default CCPARequestForm;

import React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Apply from './pages/Apply';
import Homepage from './pages/Home';
import Landing from './pages/Landing';
import NotFound from './pages/NotFound';
import CssBaseline from '@material-ui/core/CssBaseline'
import Faqs from './pages/FaqsPage';
import StylesProvider from './components/StylesProvider';
import WhyChooseUs from './pages/WhyChooseUs';
import ThankYou from './pages/ThankYou';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PrivacyPolicyCA from './pages/PrivacyPolicyCA';
import CCPARequestForm from './pages/CCPARequestForm';
import TermsofUse from './pages/TermsofUse';
import ScrollToTop from './components/ScrollToTop';

function App() {
    return (
        <StylesProvider>
            <CssBaseline />
                <BrowserRouter>
                    <ScrollToTop />
                    <Switch>
                        <Route path="/" exact component={Homepage} />
                        <Route path="/landing" exact component={Landing} />
                        <Route path="/faqs" exact component={Faqs} />
                        <Route path="/whyChooseUs" exact component={WhyChooseUs} />
                        <Route path="/privacyPolicy" exact component={PrivacyPolicy} />
                        <Route path="/privacy-policy-ca" exact component={PrivacyPolicyCA} />
                        <Route path="/ccpa_request_form" exact component={CCPARequestForm} />
                        <Route path="/terms" exact component={TermsofUse} />
                        <Route path="/thankYou" exact component={ThankYou} />
                        <Route path="/apply/:debitAmount?" exact component={Apply} />
                        <Route path="*" component={NotFound} />
                    </Switch>
                </BrowserRouter>
          
        </StylesProvider>
    );
}

export default App;

import React from "react";
import { Container } from "@material-ui/core";
import Header from "../components/Header";
import Footer from "../components/Footer";
import defaultData from "../defaultData.js";
import TermsofUsePage from "../components/TermsofUsePage";

const TermsofUse = () => {
  return (
    <React.Fragment>
      <Container
        maxWidth={false}
        disableGutters
        style={{ backgroundColor: "#ffffff" }}
      >
        <Header
          phoneNumber={defaultData.phoneNumber}
          showPhone={true}
          showWhyChooseUs={true}
          showFaqs={true}
          showCheckRates={true}
        />
        <TermsofUsePage />
        <Footer
          addressLine1={defaultData.addressLine1}
          addressLine2={defaultData.addressLine2}
          copyright={defaultData.copyright}
          email={defaultData.email}
        />
      </Container>
    </React.Fragment>
  );
};

export default TermsofUse;

import React, { useContext, useState } from 'react'
import logo from '../static/logo.png';
import phone from '../static/phone_icon.png';
import phoneWhite from '../static/phone_icon-white.png';
import Drawer from '@material-ui/core/Drawer';
import { DialogTitle, Typography, useMediaQuery } from '@material-ui/core';
import { MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckRateButton from './CheckRateButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ClassesContext } from './StylesProvider';

const useStyles = makeStyles(theme => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 0,
        width: "100%",
        paddingRight: "5px",
        paddingLeft: "5px",
        [theme.breakpoints.down('sm')]: {
            paddingTop: "38px",
            minHeight: '89px',
        },
        [theme.breakpoints.only('md')]: {
            minHeight: '83px',
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: '83px',
            maxWidth: '1170px',
        },
    },
    drawerTitle: {
        paddingLeft: '5px',
        paddingBottom: '5px',
    },
    hamburgerMenu: {
        color: '#157ebc',
        marginRight: '20px',
        alignSelf: 'center',
        width: '33px',
    },
    logoDiv: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: "10px",
        },
        [theme.breakpoints.only('md')]: {
            marginLeft: "12px",
            marginTop: "21px",
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: "2px",
        },
    },
    headerLogo: {
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            width: "248px",
            height: "44px"
        },
        [theme.breakpoints.only('md')]: {
            maxWidth: "294px",
            width: "100%",
            height: "auto",
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: "418px",
            width: "100%",
            height: "auto",
        },
    },
    link: {
        textDecoration: 'none',
    },
    phoneWrapperBar: {
        height: '33px',
        backgroundColor: '#f1731d',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'linear-gradient(#c55619, #f1731d)',
    },
    phoneWrapper: {
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            color: "#ffffff",
        },
        [theme.breakpoints.only('md')]: {
            marginRight: "2px"
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: "5px"
        },
    },
    phoneIcon: {
        [theme.breakpoints.down('sm')]: {
            width: "18px",
            height: "18px",
            color: "#ffffff",
            marginRight: "5px"
        },
        [theme.breakpoints.only('md')]: {
            width: "20px",
            height: "20px",
            marginRight: "2px"
        },
        [theme.breakpoints.up('lg')]: {
            width: "28px",
            height: "28px",
            marginRight: "5px"
        },
    },
    phoneText: {
        width: "auto",
        height: "28px",
        fontFamily: "OpenSans, Sans-Serif",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px",
            lineHeight: "0.8",
            color: "#ffffff",
            height: '16px',
            verticalAlign: 'baseline',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: "13px",
            lineHeight: "1.85",
            color: "#f38823",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "18px",
            lineHeight: "1.33",
            color: "#f38823",
        },
    },
    headerLogoDialog: {
        width: '248px',
        height: '44px',
        marginTop: '10px',
        marginLeft: '9px',
    },
    drawerPaper: {
        width: 'calc(100% - 55px)',
    },
    menuContainer: {
        display: 'flex',
        alignItems: 'space-between',
        flexDirection: 'column',
    },
    menu: {
        width: '100%',
        height: '60px',
        backgroundColor: '#064c75',
        border: '1px solid #ffffff',
        paddingLeft: '44px',
        display: 'flex',
        alignItems: 'center',
    },
    faqText: {
        alignSelf: 'center',
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#173459',
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            color: "#ffffff",
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '11px',
            lineHeight: '2.18',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '14px',
            lineHeight: '1.71',
        },
        whiteSpace: 'nowrap',
    },
    whyChooseUsText: {
        alignSelf: 'center',
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#173459',
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            color: "#ffffff",
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '11px',
            lineHeight: '2.18',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '14px',
            lineHeight: '1.71',
        },
    },
    menuText: {
        fontFamily: 'OpenSans, Sans-Serif',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.71',
        letterSpacing: 'normal',
        color: '#ffffff',
        textDecoration: 'none',
    },
    menuChoose: {
        height: '60px',
        border: '1px solid #ffffff',
        backgroundColor: '#f27e20',
        paddingLeft: '44px',
        display: 'flex',
        alignItems: 'center',
    },
    mobileCloseIconDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
    },
    mobleCloseIcon: {
        marginRight: '17px',
        fontFamily: 'OpenSans, Sans-Serif',
        fontSize: '32px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#157ebc',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        height: 'min-content',
        borderBottom: 'solid #ffffff 3px',
        '&:hover': {
            borderBottom: 'solid #f27e20 3px',
        },
    },
    underlined: {
        borderBottom: 'solid #f27e20 3px',
    },
}));

type Props = {
    phoneNumber: string;
    showPhone?: boolean;
    showWhyChooseUs?: boolean;
    showFaqs?: boolean;
    showCheckRates?: boolean;
}

const Header = ({ phoneNumber, showPhone, showWhyChooseUs, showFaqs, showCheckRates }: Props) => {
    const classes = useStyles();
    const themedClasses = useContext(ClassesContext);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();

    const history = useHistory();

    const handleHomeClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setPopoverOpen(false);
        history.push("/");
    }

    const handleApplyClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setPopoverOpen(false);
        history.push("/apply");
    }

    const handleFaqsClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setPopoverOpen(false);
        history.push("/faqs");
    }

    const handleWhyChooseUsClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setPopoverOpen(false);
        history.push("/whyChooseUs");
    }

    return <React.Fragment>
        <div className={classes.responsiveWrapper}>
            {!isMobile && <div className={classes.header}>
                <div onClick={handleHomeClick} className={classes.logoDiv}>
                    <img src={logo} className={classes.headerLogo} alt="Logo" />
                </div>
                {showWhyChooseUs && <span className={classes.wrapper + ' ' + (location.pathname === '/whyChooseUs' ? classes.underlined : '')}>
                    <Link to="/whyChooseUs" className={classes.whyChooseUsText}>WHY CHOOSE US?</Link>
                </span>}
                {showFaqs && <span className={classes.wrapper + ' ' + (location.pathname === '/faqs' ? classes.underlined : '')}>
                    <Link to="/faqs" className={classes.faqText}>FAQs</Link>
                </span>}
                {showPhone && !isMobile && <div className={themedClasses.phoneDiv}>
                    <div className={classes.phoneWrapper}>
                        <img src={phone} className={classes.phoneIcon} alt="Phone Logo" />
                        <a className={classes.link} href={'tel:' + phoneNumber}><Typography className={classes.phoneText}>{phoneNumber}</Typography></a>
                    </div>
                </div>}
                {showCheckRates && <CheckRateButton />}
            </div>}

            {isMobile && <div className={classes.header}>
                <div onClick={handleHomeClick} className={classes.logoDiv}>
                    <img src={logo} className={classes.headerLogo} alt="Logo" />
                </div>
                {!popoverOpen && isMobile && <MenuIcon fontSize="large" className={classes.hamburgerMenu} onClick={() => setPopoverOpen(true)} />}
                {popoverOpen && <div className={classes.mobileCloseIconDiv}>
                    <span className={classes.mobleCloseIcon} onClick={() => setPopoverOpen(false)}>X</span>
                </div>}
            </div>}
        </div>

        {isMobile && <div className={classes.phoneWrapperBar}>
            <span className={classes.phoneWrapper}>
                <img src={phoneWhite} className={classes.phoneIcon} alt="Phone Logo" />
                <a className={classes.link} href={'tel:' + phoneNumber}><Typography className={classes.phoneText}>{phoneNumber}</Typography></a>
            </span>
        </div>}

        <MuiThemeProvider theme={theme}>
            <Drawer
                variant="persistent"
                anchor="left"
                open={popoverOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <DialogTitle disableTypography className={classes.drawerTitle}>
                    <img src={logo} className={classes.headerLogoDialog} alt="Logo" />
                </DialogTitle>
                <div className={classes.menuContainer}>
                    <div onClick={handleWhyChooseUsClick} className={classes.menu}><Typography className={classes.menuText}>WHY CHOOSE US?</Typography></div>
                    <div onClick={handleFaqsClick} className={classes.menu} ><Link to="/faqs" className={classes.menuText}>FAQs</Link></div>
                    <a className={classes.link} href={'tel:' + phoneNumber}>
                        <div className={classes.menu}>
                            <img src={phoneWhite} className={classes.phoneIcon} alt="Phone Logo" />
                            <Typography className={classes.menuText}>{phoneNumber}</Typography>
                        </div>
                    </a>
                    <div onClick={handleApplyClick} className={classes.menuChoose} ><Typography className={classes.menuText}>CHECK RATES</Typography></div>
                </div>
            </Drawer>
        </MuiThemeProvider>
    </React.Fragment>
}

export default Header
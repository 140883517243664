import React, { useContext, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    disclaimerWrapper: {
        display: "flex",
        padding:"0px",
        margin:'auto',
        marginTop:'10px',
        marginBottom:'10px',
        justifyContent: "center",
        width:"100%",
        [theme.breakpoints.down('sm')]: {
            width:"100%",
        },
        [theme.breakpoints.only('md')]: {
            width: '333px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '433px',
        },
    },
    disclaimerText:{
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.2',
        letterSpacing: 'normal',
        color: '#5f5e5e',
        fontSize: '14px',
        alignSelf: 'center',
        textAlign: 'justify',
        textJustify: 'inter-word',
        margin:'0px', 
        [theme.breakpoints.down('sm')]: {
            fontSize: '11px',
            paddingLeft: '17px',
            paddingRight: '17px'
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '11px',
           
        },
        [theme.breakpoints.up('lg')]: {
          
        },
    }
}));

const FormDisclaimer = () => {
    const classes = useStyles();
    return <div className={classes.disclaimerWrapper}>
      <Typography className={classes.disclaimerText} paragraph={true}>
      By clicking <span>&#39;</span>Check Your Rate<span>&#39;</span>, you agree that the phone number you are providing may be used to contact you by a trusted partner (including autodialed and prerecorded calls, as well as text/SMS messages). Msg. and data rates apply, and your consent to such contact is not required for purchase. To receive more options on how to manage communications with us, click our privacy policy.
      </Typography>
    </div>
}
export default FormDisclaimer; 
import React, { useContext, useState } from "react";
import validator from "validator";
import { Container } from "@material-ui/core";

import AccreditedBar from "../components/AccreditedBar";
import DoYouQualify from "../components/DoYouQualify";
import { RouteComponentProps } from "react-router-dom";
import { StylesContext } from "../components/StylesProvider";
import Header from "../components/Header";
import Footer from "../components/Footer";
import defaultData from "../defaultData.js";
import { useHistory } from "react-router-dom";
import applySubmit from "../api/apply-api.js";

export type FormErrors = {
  debitAmount: boolean;
  firstName: boolean;
  lastName: boolean;
  phone: boolean;
  email: boolean;
};

const Apply = ({ match }: RouteComponentProps<any>) => {
  const setThemeName = useContext(StylesContext);
  setThemeName("apply");

  const selectDebitAmount = (debitAmount: number): string => {
    if (debitAmount < 5000) {
      return "0 - $4,999";
    } else if (debitAmount < 7500) {
      return "$5,000 - $7,499";
    } else if (debitAmount < 10000) {
      return "$7,500 - $9,999";
    } else if (debitAmount < 15000) {
      return "$10,000 - $14,999";
    } else if (debitAmount < 20000) {
      return "$15,000 - $19,999";
    } else if (debitAmount < 30000) {
      return "$20,000 - $29,999";
    } else if (debitAmount < 40000) {
      return "$30,000 - $39,999";
    } else if (debitAmount < 50000) {
      return "$40,000 - $49,999";
    } else if (debitAmount < 60000) {
      return "$50,000 - $59,999";
    } else if (debitAmount < 70000) {
      return "$60,000 - $69,999";
    } else if (debitAmount < 80000) {
      return "$70,000 - $79,999";
    } else if (debitAmount < 90000) {
      return "$80,000 - $89,999";
    } else if (debitAmount < 99999) {
      return "$90,000 - $99,999";
    }

    return "$100,000";
  };

  const [debitAmount, setDebitAmount] = useState(
    match.params.debitAmount === undefined
      ? ""
      : selectDebitAmount(match.params.debitAmount)
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState<FormErrors>({
    debitAmount: false,
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
  });
  const history = useHistory();

  const submitForm = async (body: string) => {
    const submitResponse = await applySubmit(body);

    // @ts-ignore
    if (submitResponse.status === 200) {
      history.push("/thankyou", { firstName: firstName });
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      var body = JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
        email_address: email,
        debt_amount: debitAmount,
        cookie_enabled: navigator.cookieEnabled,
        url_of_page: window.location.href,
        web_referrer: document.referrer || "",
      });
      submitForm(body);
    }
  };

  const validate = () => {
    let errorFound = false;
    const errors: FormErrors = {
      debitAmount: false,
      firstName: false,
      lastName: false,
      phone: false,
      email: false,
    };

    if (debitAmount === "") {
      errors.debitAmount = true;
      errorFound = true;
    }

    if (firstName === "") {
      errors.firstName = true;
      errorFound = true;
    }

    if (lastName === "") {
      errors.lastName = true;
      errorFound = true;
    }

    if (!validator.isMobilePhone(phone, "en-US")) {
      errors.phone = true;
      errorFound = true;
    }

    if (!validator.isEmail(email)) {
      errors.email = true;
      errorFound = true;
    }

    setFormErrors(errors);

    return !errorFound;
  };

  return (
    <React.Fragment>
      <Container
        maxWidth={false}
        disableGutters
        style={{ backgroundColor: "#ffffff" }}
      >
        <Header
          phoneNumber={defaultData.phoneNumber}
          showPhone={true}
          showWhyChooseUs={false}
          showFaqs={false}
          showCheckRates={false}
        />
        <DoYouQualify
          debitAmount={debitAmount}
          firstName={firstName}
          lastName={lastName}
          phone={phone}
          email={email}
          setDebitAmount={setDebitAmount}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setPhone={setPhone}
          setEmail={setEmail}
          handleSubmit={handleSubmit}
          formErrors={formErrors}
        />
        <AccreditedBar />
        <Footer
          addressLine1={defaultData.addressLine1}
          addressLine2={defaultData.addressLine2}
          copyright={defaultData.copyright}
          email={defaultData.email}
        />
      </Container>
    </React.Fragment>
  );
};

export default Apply;

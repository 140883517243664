import React, { useContext } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormSelect from './FormSelect';
import FormInput from './FormInput';
import FormDisclaimer from './FormDisclaimer';
import { Typography, Radio, useMediaQuery, useTheme } from '@material-ui/core';
import GetResultsButton from './GetResultsButton';
import { FormErrors } from '../pages/Home';
import { ClassesContext } from './StylesProvider';

const useStyles = makeStyles(theme => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    form: {
        backgroundColor: '#f1f4f4',
        display:'flex',
        flexDirection: 'column',
        height: 'fit-content',
        paddingBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '21px',
        },
        [theme.breakpoints.only('md')]: {
            borderRadius: '8px',
            border: 'solid 3px #ffffff',
            webkitBoxShadow: '0px 0px 25px 0px rgba(0,0,0,0.75)',
            mozBoxShadow: '0px 0px 25px 0px rgba(0,0,0,0.75)',
            boxShadow: '0px 0px 25px 0px rgba(0,0,0,0.75)',
            width: '417px',
            
           
        },
        [theme.breakpoints.up('lg')]: {
            borderRadius: '8px',
            border: 'solid 3px #ffffff',
            webkitBoxShadow: '0px 0px 25px 0px rgba(0,0,0,0.75)',
            mozBoxShadow: '0px 0px 25px 0px rgba(0,0,0,0.75)',
            boxShadow: '0px 0px 25px 0px rgba(0,0,0,0.75)',
            width: '541px',
        },
    },
    buttonWrapper: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '17px',
            paddingRight: '17px',
            paddingBottom: '10px',
        },
    },
    formInput: {
        borderRadius: '4px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '12px',
            width: '100%',
            paddingLeft: '17px',
            paddingRight: '17px',
        },
        [theme.breakpoints.only('md')]: {
            backgroundColor: '#ffffff',
            marginTop: '15px',
            marginLeft: '43px',
            marginRight: '41px',
            height: '39px',
            width: '333px',
        },
        [theme.breakpoints.up('lg')]: {
            backgroundColor: '#ffffff',
            marginTop: '21px',
            marginLeft: '54px',
            marginRight: '54px',
            height: '50px',
            width: '433px',
        },
    },
}));

type Props = {
    debitAmount: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    setDebitAmount: (debitAmount: string) => void;
    setFirstName: (firstName: string) => void;
    setLastName: (lastName: string) => void;
    setPhone: (phone: string) => void;
    setEmail: (email: string) => void;
    handleSubmit: () => void;
    formErrors: FormErrors;
}

const DoYouQualify = ({
    debitAmount,
    firstName,
    lastName,
    phone,
    email,
    setDebitAmount,
    setFirstName,
    setLastName,
    setPhone,
    setEmail,
    handleSubmit,
    formErrors,
}: Props) => {
    const classes = useStyles();
    const themedClasses = useContext(ClassesContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (isMobile) {
        return <div >
            <div className={classes.form}>
                <div className={themedClasses.homeBackgroundImage}>
                    <Typography className={themedClasses.formHeadline}>DO YOU QUALIFY FOR DEBT CONSOLIDATION?</Typography>
                </div>
                <FormSelect id="debitAmount" label="debitAmount" value={debitAmount} setValue={setDebitAmount} error={formErrors.debitAmount} />
                <FormInput handleSubmit={handleSubmit} className={classes.formInput} id="first-name" label="First Name" value={firstName} setValue={setFirstName} error={formErrors.firstName} />
                <FormInput handleSubmit={handleSubmit} className={classes.formInput} id="last-name" label="Last Name" value={lastName} setValue={setLastName} error={formErrors.lastName} />
                <FormInput handleSubmit={handleSubmit} className={classes.formInput} id="phone-number" label="Phone Number" value={phone} setValue={setPhone} mask={"(999) 999-9999"} error={formErrors.phone} />
                <FormInput handleSubmit={handleSubmit} className={classes.formInput} id="email" label="Email Address" value={email} setValue={setEmail} error={formErrors.email} />
                <div className={classes.buttonWrapper}><GetResultsButton handleSubmit={handleSubmit} /></div>
                <FormDisclaimer></FormDisclaimer>

            </div>
        </div>
    }

    return <div className={classes.responsiveWrapper}>
        <div className={themedClasses.imageWrapper}>
            <div className={themedClasses.headerImage}>
                <div className={themedClasses.homeBackgroundImage}>
                    <div className={classes.form}>
                        <Typography className={themedClasses.formHeadline}>DO YOU QUALIFY FOR DEBT CONSOLIDATION?</Typography>
                        <FormSelect id="debitAmount" label="debitAmount" value={debitAmount} setValue={setDebitAmount} error={formErrors.debitAmount} />
                        <FormInput handleSubmit={handleSubmit} className={classes.formInput} id="first-name" label="First Name" value={firstName} setValue={setFirstName} error={formErrors.firstName} />
                        <FormInput handleSubmit={handleSubmit} className={classes.formInput} id="last-name" label="Last Name" value={lastName} setValue={setLastName} error={formErrors.lastName} />
                        <FormInput handleSubmit={handleSubmit} className={classes.formInput} id="phone-number" label="Phone Number" value={phone} setValue={setPhone} mask={"(999) 999-9999"} error={formErrors.phone} />
                        <FormInput handleSubmit={handleSubmit} className={classes.formInput} id="email" label="Email Address" value={email} setValue={setEmail} error={formErrors.email} />
                        <GetResultsButton handleSubmit={handleSubmit} />
                        <FormDisclaimer></FormDisclaimer>

                    </div>
                </div>
            </div>
        </div>

    </div>
}

export default DoYouQualify;
import React, { useContext } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createMuiTheme, FormControl, InputLabel, MenuItem, MuiThemeProvider, Select } from '@material-ui/core';
import { ClassesContext } from './StylesProvider';

const useStyles = makeStyles(theme => ({
    error: {
        backgroundColor: '#ffc3c3',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '6px',
            paddingBottom: '7px',
        },
        [theme.breakpoints.only('md')]: {
            paddingTop: '8px',
            paddingBottom: '8px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '12px',
            paddingBottom: '12px',
        },
    },
    rawInput: {
        [theme.breakpoints.down('sm')]: {
            height: '25px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '15px',
            height: '39px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '15px',
            height: '33px',
        },
    },
    selectLabel: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 600,
        color: '#717171',
        [theme.breakpoints.down('sm')]: {
            marginTop: '-8px',
            height: '39px',
            fontSize: '16px',
            paddingLeft: '18px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '-8px',
            height: '50px',
            fontSize: '16px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '-3px',
            height: '50px',
            fontSize: '21px',
        },
    },
}));

const selectTheme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                fontFamily: 'ProximaNova, Sans-serif',
                fontSize: '21px',
                fontWeight: 600,
                color: '#717171',
            }
        },
        MuiInput: {
            input: {
                marginTop: '0px !important',
                paddingBottom: '0px',
            }
        },
        MuiSelect: {
            root: {
            }
        }
    }
});

type Props = {
    label: string;
    value: string;
    id: string;
    setValue: (value: string) => void;
    error?: boolean;
}

const FormSelect = ({ label, id, value, setValue, error }: Props) => {
    const classes = useStyles();
    const themedClasses = useContext(ClassesContext);

    const handleDebitAmountChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string);
    };

    return <MuiThemeProvider theme={selectTheme}>
        <FormControl variant="outlined" className={themedClasses.debitAmountSelectWrapper}>
            <InputLabel
                className={classes.selectLabel}
                id="form-select-label"
                shrink={false}
            >
                {value === '' && "Select Debt Amount"}
            </InputLabel>
            <Select
                className={themedClasses.debitAmountSelect}
                id="form-select"
                labelId="form-select-label"
                value={value}
                onChange={handleDebitAmountChange}
                error={error}
                inputProps={{
                    className: error ? classes.error : classes.rawInput
                }}
            >
                <MenuItem value={'0 - $4,999'}>0 - $4,999</MenuItem>
                <MenuItem value={'$5,000 - $7,499'}>$5,000 - $7,499</MenuItem>
                <MenuItem value={'$7,500 - $9,999'}>$7,500 - $9,999</MenuItem>
                <MenuItem value={'$10,000 - $14,999'}>$10,000 - $14,999</MenuItem>
                <MenuItem value={'$15,000 - $19,999'}>$15,000 - $19,999</MenuItem>
                <MenuItem value={'$20,000 - $29,999'}>$20,000 - $29,999</MenuItem>
                <MenuItem value={'$30,000 - $39,999'}>$30,000 - $39,999</MenuItem>
                <MenuItem value={'$40,000 - $49,999'}>$40,000 - $49,999</MenuItem>
                <MenuItem value={'$50,000 - $59,999'}>$50,000 - $59,999</MenuItem>
                <MenuItem value={'$60,000 - $69,999'}>$60,000 - $69,999</MenuItem>
                <MenuItem value={'$70,000 - $79,999'}>$70,000 - $79,999</MenuItem>
                <MenuItem value={'$80,000 - $89,999'}>$80,000 - $89,999</MenuItem>
                <MenuItem value={'$90,000 - $99,999'}>$90,000 - $99,999</MenuItem>
                <MenuItem value={'$100,000'}>$100,000+</MenuItem>
            </Select>
        </FormControl>
    </MuiThemeProvider>
}

export default FormSelect
import React from 'react';
import mail from '../static/mail_logo.png';
import address from '../static/address_logo.png';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: "#e1e7f0",
        paddingLeft:"15px",
        paddingRight:"15px"
    },
    footer: {
        display: 'flex',
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            minHeight: "114px",
            maxWidth: "365px",
            flexDirection: "column",
        },
        [theme.breakpoints.only('md')]: {
            minHeight: "112px",
            maxWidth: "1019px",
            flexDirection: "row",
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: "112px",
            width: '100%',
            maxWidth: '1170px',
            flexDirection: "row",
        },
    },
    footerMobile: {
        display: 'flex',
        justifyContent: "space-between",
        minHeight: "114px",
        width: "100%",
        flexDirection: "column",
    },
    mobileRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: '15px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '100%',
    },
    linksDiv: {
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'flex-start',
            marginLeft: "10px",
            marginTop: "0px",
            marginBottom: "19px",
        },
        [theme.breakpoints.only('md')]: {
            alignSelf: 'flex-start',
            marginLeft: "41px",
            marginTop: "34px",
        },
        [theme.breakpoints.up('lg')]: {
       
        },
    },
    linksDivMobile: {
        alignSelf: 'flex-start',
        marginLeft: "10px",
        marginTop: "0px",
        marginBottom: "19px",
    },
    link: {
        textDecoration: 'none',
        marginLeft: '2px',
        marginRight: '3px',
        whiteSpace: 'nowrap'
    },
    linksWrapper: {
        fontFamily: "ProximaNova, Sans-Serif",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#5a5959",
        [theme.breakpoints.down('sm')]: {
            fontSize: "11px",
            lineHeight: "1.73",
        },
        [theme.breakpoints.only('md')]: {
            alignSelf: 'flex-start',
            fontSize: "11px",
            lineHeight: "1.82",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "13px",
            lineHeight: "1.85",
        },

    },
    addressDiv: {
        alignSelf: 'center',
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginRight: '10px',
        },
        [theme.breakpoints.only('md')]: {
            marginLeft: "19px",
        },
        [theme.breakpoints.up('lg')]: {
        },
    },
    addressDivMobile: {
        alignSelf: 'center',
        display: "flex",
        flexDirection: "row",
        marginLeft: '10px',
        marginRight: '10px',
    },
    addressTextWrapper: {
        alignSelf: "center",
        fontFamily: "ProximaNova, Sans-Serif",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#5a5959",
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            fontSize: "11px",
            lineHeight: "1.45",
        },
        [theme.breakpoints.only('md')]: {
            fontSize: "11px",
            lineHeight: "1.82",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "15px",
            lineHeight: "1.6",
        },
    },
    addressLogo: {
        [theme.breakpoints.only('md')]: {
            width: "13px",
            height: "19px",
            marginRight: "5px",
            marginLeft: "5px",
        },
        [theme.breakpoints.up('lg')]: {
            alignSelf: "center",
            width: "21px",
            height: "31px",
            marginRight: "15px",
            marginLeft: "5px",
        },
    },
    infoDiv: {
        alignSelf: 'center',
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginRight: '10px',
        },
        [theme.breakpoints.only('md')]: {
            alignSelf: 'flex-start',
            marginTop: "32px",
            marginLeft: "35px",
            marginRight: "17px",
        },
        [theme.breakpoints.up('lg')]: {
          
        },
    },
    infoDivMobile: {
        alignSelf: 'center',
        display: "flex",
        flexDirection: "row",
        marginLeft: '10px',
        marginRight: '10px',
    },
    infoTextWrapper: {
        alignSelf: "center",
        fontFamily: "ProximaNova, Sans-Serif",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#5a5959",
        [theme.breakpoints.down('sm')]: {
            fontSize: "11px",
            lineHeight: "1.45",
        },
        [theme.breakpoints.only('md')]: {
            fontSize: "11px",
            lineHeight: "1.82",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "15px",
            lineHeight: "1.6",
        },
    },
    infoLogo: {
        alignSelf: "center",
        marginLeft: "22px",
        [theme.breakpoints.only('md')]: {
            width: "18px",
            height: "13px",
            marginRight: "5px",
        },
        [theme.breakpoints.up('lg')]: {
            width: "28px",
            height: "21px",
            marginRight: "22px",
        },
    },
    verticalLine: {
        borderLeft: "1px solid #5a5959",
    },
}));

type Props = {
    addressLine1: string;
    addressLine2: string;
    copyright: string;
    email: string;
}

const Footer = ({ addressLine1, addressLine2, copyright, email }: Props) => {
    const classes = useStyles();
    const showMobile = useMediaQuery('@media (max-width:960px)');

    if (showMobile) {
        return <div className={classes.responsiveWrapper}>
            <div className={classes.footerMobile}>
                <div className={classes.mobileRow}>
                    <div className={classes.addressDivMobile}>
                        <Typography className={classes.addressTextWrapper}>
                            {addressLine1}<br />{addressLine2}
                        </Typography>
                    </div>
                    <div className={classes.verticalLine} />
                    <div className={classes.infoDivMobile}>
                        <Typography className={classes.infoTextWrapper}>
                            {email}
                        </Typography>
                    </div>
                </div>
                <div className={classes.mobileRow}>
                    <div className={classes.linksDivMobile}>
                        <Typography className={classes.linksWrapper}>
                            <a href="/" className={classes.link}>Home</a>|
                            <a href="/whyChooseUs" className={classes.link}>Why Choose Us?</a>|
                            <a href="/faqs" className={classes.link}>FAQs</a>|
                            <a href="/privacyPolicy" className={classes.link}>Privacy Policy</a><br />
                            {copyright}
                        </Typography>
                    </div>
                </div>
            </div >
        </div >
    }

    return <div className={classes.responsiveWrapper}>
        <div className={classes.footer}>
            <div className={classes.linksDiv}>
                <Typography className={classes.linksWrapper}>
                    <a href="/" className={classes.link}>Home</a>|
                    <a href="/whyChooseUs" className={classes.link}>Why Choose Us?</a>|
                    <a href="/faqs" className={classes.link}>FAQs</a>|
                    <a href="/privacyPolicy" className={classes.link}>Privacy Policy</a>|
                    <a href="/terms" className={classes.link}>Terms of Use</a><br />
                    {copyright}
                </Typography>
            </div>
            <div className={classes.addressDiv}>
                <img src={address} className={classes.addressLogo} alt="Address Logo" />
                <Typography className={classes.addressTextWrapper}>
                    {addressLine1}<br />{addressLine2}
                </Typography>
            </div>
            <div className={classes.infoDiv}>
                <img src={mail} className={classes.infoLogo} alt="Information Logo" />
                <a style={{ textDecoration: 'none' }} href={"mailto:" + email}>
                    <Typography className={classes.infoTextWrapper}>
                        {email}
                    </Typography>
                </a>
            </div>
        </div>
    </div >
}

export default Footer
import React, { useRef, useEffect, useState } from "react";
import { select, scaleLinear, scaleBand } from "d3";
import { makeStyles } from "@material-ui/core";
import VisibilitySensor from 'react-visibility-sensor';

const useStyles = makeStyles({
    svg: {
        overflow: 'visible',
        marginBottom: '2rem',
        display: 'block',
    },
    cashMoney: {
        color: '#ffffff',
        fontSize: '30px',
    },
});

type Bar = {
    value: number;
    breakpoint: number;
}

type Props = {
    bars: Bar[];
    viewportWidth: string;
    viewportHeight: string;
}
const BarChart = ({ bars, viewportWidth, viewportHeight }: Props) => {
    const [showChart, setShowChart] = useState(false);
    const [data] = useState<number[]>(bars.map(bar => bar.value).reverse());
    const classes = useStyles();
    const leftMargin = 350;
    const topMargin = 100;
    const totalHeight = 380;
    const totalWidth = 530;
    const margin = { top: 10, right: 10, bottom: 25, left: 25 }
    const height = totalHeight - margin.top - margin.bottom
    const width = totalWidth - margin.left - margin.right
    const maxValue = Math.max(...bars.map(bar => bar.value));;
    const svgRef = useRef(null);

    const xScale = scaleLinear<number>()
        .domain([0, maxValue])
        .range([0, width]);

    const yScale = scaleBand()
        .domain(data.map((value: number, index: number) => index.toString()))
        .range([0, height])
        .padding(0.17);

    const colorScale = scaleLinear<string>()
        .domain(bars.map(bar => bar.value).reverse())
        .range(["#064069", "#1473b3", "#4b98c1"])
        .clamp(true);

    const colorBreakpointScale = scaleLinear<string>()
        .domain(bars.map(bar => bar.breakpoint).reverse())
        .range(["#064069", "#1473b3", "#4b98c1"])
        .clamp(true);

    useEffect(() => {
        if (svgRef.current) {
            const svg = select(svgRef.current);

            if (showChart) {
                svg
                    .selectAll(".bar")
                    .remove();
                svg
                    .selectAll("text.rightText")
                    .remove();
                svg
                    .selectAll("text.breakpoint")
                    .remove();
                svg
                    .selectAll(".bar")
                    .data(data)
                    .join("rect")
                    .attr("class", "bar")
                    .style("transform", "scale(1, -1)")
                    .attr("y", (value, index) => {
                        const xPos = yScale(index.toString());

                        if (xPos !== undefined) {
                            return xPos - height - topMargin
                        }

                        return leftMargin;
                    })
                    .attr("x", leftMargin)
                    .attr("height", yScale.bandwidth())
                    .transition()
                    .attr("fill", colorScale)
                    .attr("width", (value: number) => width - (width - xScale(value)));

                svg
                    .selectAll("text.rightText")
                    .data(bars).enter()
                    .append("text")
                    .attr("class", "rightText")
                    .attr('text-anchor', 'end')
                    .style("font-size", "48px")
                    .style("font-weight", "800")
                    .attr("fill", "#fff")
                    .attr("x", (bar: Bar, index: number) => {
                        const xPos = width - (width - xScale(bar.value));

                        if (xPos !== undefined) {
                            return leftMargin + xPos - 10
                        }

                        return leftMargin;
                    })
                    //@ts-ignore
                    .attr("y", (value, index) => yScale(index.toString()) + topMargin + yScale.bandwidth() / 2 + 17)
                    .text((bar: Bar) => '$' + new Intl.NumberFormat('en-US',
                        { style: 'decimal', currency: 'USD' }
                    ).format(bar.value))
                    .style("opacity", 0)
                    .transition()
                    .duration(2000)
                    .style("opacity",1)

                svg
                    .selectAll("text.breakpoint")
                    .data(bars).enter()
                    .append("text")
                    .attr("class", "breakpoint")
                    .attr('text-anchor', 'end')
                    .style("font-size", "48px")
                    .style("font-weight", "800")
                    .attr("fill", (bar: Bar) => colorBreakpointScale(bar.breakpoint))
                    .attr("x", (value, index) => {
                        const xPos = yScale(index.toString());

                        if (xPos !== undefined) {
                            return leftMargin - 50
                        }

                        return leftMargin;
                    })
                    //@ts-ignore
                    .attr("y", (value, index) => yScale(index.toString()) + topMargin + yScale.bandwidth() / 2 + 17)
                    .text((bar: Bar) => '$' + new Intl.NumberFormat('en-US',
                        { style: 'decimal', currency: 'USD' }
                    ).format(bar.breakpoint))
                    .style("opacity", 0)
                    .transition()
                    .duration(1000)
                    .style("opacity",1)

                svg.append("line")
                    .attr("x1", 50)
                    .attr("x2", 300)
                    .attr("y1", 100)
                    .attr("y2", 100)
                    .attr("stroke-width", 6)
                    .attr("stroke", "#9b9a9a")
                svg.append("text")
                    .attr("x", 300)
                    .attr("y", 60)
                    .attr('text-anchor', 'end')
                    .attr('font-family', 'OpenSans, Sans-serif')
                    .style("font-size", "20px")
                    .style("font-weight", "800")
                    .text("Balanced Owed");
                svg.append("text")
                    .attr("x", 300)
                    .attr("y", 84)
                    .attr('text-anchor', 'end')
                    .attr('font-family', 'OpenSans, Sans-serif')
                    .style("font-size", "20px")
                    .style("font-weight", "800")
                    .text("on 18% APR Card");

                svg.append("line")
                    .attr("x1", 350)
                    .attr("x2", 845)
                    .attr("y1", 100)
                    .attr("y2", 100)
                    .attr("stroke-width", 6)
                    .attr("stroke", "#9b9a9a")
                svg.append("text")
                    .attr("x", 845)
                    .attr("y", 60)
                    .attr('text-anchor', 'end')
                    .attr('font-family', 'OpenSans, Sans-serif')
                    .style("font-size", "20px")
                    .style("font-weight", "800")
                    .text("Total Savings on Low Rate");
                svg.append("text")
                    .attr("x", 845)
                    .attr("y", 84)
                    .attr('font-family', 'OpenSans, Sans-serif')
                    .style("font-size", "20px")
                    .style("font-weight", "800")
                    .attr('text-anchor', 'end')
                    .text("Debit Consolidation Loan");
            }
        }
    }, [data, bars, colorBreakpointScale, colorScale, height, width, xScale, yScale, showChart]);

    return (
        <React.Fragment>
            <VisibilitySensor>
                {({ isVisible }) => {
                    if(!showChart && isVisible) {
                        setShowChart(true);
                    }

                    return <svg className={classes.svg} ref={svgRef} width={viewportWidth} height={viewportHeight}  viewBox="0 0 911 470">
                        <g className="x-axis" />
                        <g className="y-axis" />
                    </svg>
                }}
            </VisibilitySensor>

        </React.Fragment>
    );
}

export default BarChart;

import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import middleImage from '../static/thankyou_image.png';
import checkmark from '../static/home/checkmark.png';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#f1f4f4',
        },
        [theme.breakpoints.only('md')]: {
            backgroundColor: '#f1f4f4',
        },
        [theme.breakpoints.up('lg')]: {
            backgroundColor: '#f1f4f4',
        },
    },
    body: {
        maxWidth: '100%',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
            paddingLeft: '17px',
            paddingRight: '0px',
            paddingTop: '27px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '30px',
            paddingLeft: '53px',
            paddingRight: '23px',
            paddingTop: '85px',
            paddingBottom: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '45px',
            paddingRight: '25px',
            paddingTop: '85px',
            paddingBottom: '80px',
            maxWidth: '1170px',
            // width: '95%',
            fontSize: '50px',
        },
    },
    header: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2b6587',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '50px',
        },
    },
    bulletWrapper: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '15px',
    },
    bulletCheckmark: {
        alignSelf: 'center',
        width: '31px',
        height: '30px',
    },
    bulletDescription: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        textAlign: 'left',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
            lineHeight: '1.33',
            letterSpacing: '0.6px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '14px',
            lineHeight: '1.36',
            letterSpacing: '0.35px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '24px',
            lineHeight: '1.33',
            letterSpacing: '0.6px',
        },
    },
    bulletText: {
        alignSelf: 'center',
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 600,
        textAlign: 'left',
        width: '100%',
        color: '#585859',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '15px',
        },
        [theme.breakpoints.only('md')]: {
            paddingLeft: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '24px',
        },
    },
    columnWrapper: {
        display: 'flex',
        justifyContent: "start",
        flexDirection: 'column',
        width: '50%',
    },
    rowWrapper: {
        display: 'flex',
        justifyContent: "space-between",
        flexDirection: 'row',
    },
    bottomWrapper: {
        marginTop: '5px',
        display: 'flex',
        justifyContent: "space-between",
    },
    checkboxesWrapper: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#585859',
        marginTop: '28px',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '17px',
            fontSize: '16px',
            lineHeight: '1.13',
        },
        [theme.breakpoints.only('md')]: {
            marginRight: '30px',
            fontSize: '15px',
            lineHeight: '1.37',
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: '30px',
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    middleText: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            marginTop: '21px',
            paddingRight: '17px',
            fontSize: '16px',
            lineHeight: '1.06',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '20px',
            marginBottom: '24px',
            fontSize: '15px',
            lineHeight: '1.53',
            marginRight: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '32px',
            marginBottom: '24px',
            marginRight: '30px',
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    bottomText: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#585859',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '45px',
            paddingRight: '17px',
            fontSize: '16px',
            lineHeight: '1.06',
        },
        [theme.breakpoints.only('md')]: {
            marginBottom: '24px',
            fontSize: '15px',
            lineHeight: '1.53',
            marginRight: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '24px',
            marginRight: '30px',
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    conclusion: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            marginRight: '17px',
            fontSize: '16px',
            lineHeight: '1.13',
        },
        [theme.breakpoints.only('md')]: {
            width: '456px',
            fontSize: '15px',
            lineHeight: '1.37',
        },
        [theme.breakpoints.up('lg')]: {
            width: '785px',
            height: '117px',
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    middlePic: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '23px',
            maxWidth: '100%',
            height: 'auto',
        },
        [theme.breakpoints.only('md')]: {
            maxWidth: '100%',
            height: 'auto',
            float: 'right',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
            height: 'auto',
            float: 'right',
        },
    },
    horizontalLine: {
        marginTop: '34px',
        height: '3px',
        width: '100%',
    },
}));

const OurClientCommitment = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (isMobile) {
        return <div className={classes.responsiveWrapper}>
            <div className={classes.body}>
                <div className={classes.header}>Our Client Commitment</div>
                <img src={middleImage} className={classes.middlePic} alt="Man on phone." />
                <div className={classes.middleText}>
                    Our organization is full of dedicated and compassionate individuals focused on providing you with the best possible service. We strive for excellence every day so that we can make a difference in your financial life.
                </div>
                <div className={classes.checkboxesWrapper}>
                    <div className={classes.bulletWrapper}>
                        <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                        <span className={classes.bulletText}>
                            Excellent client service representatives
                        </span>
                    </div>
                    <div className={classes.bulletWrapper}>
                        <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                        <span className={classes.bulletText}>
                            Best solutions for financial hardships
                        </span>
                    </div>
             
                    <div className={classes.bulletWrapper}>
                        <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                        <span className={classes.bulletText}>
                            Referrals for friends and family members
                        </span>
                    </div>
                </div>

                <Typography className={classes.bottomText}>
                    Debt Consolidation Loans is here for you during your time of need. The more debt accounts you’re responsible for, the harder it is to keep track of your finances and the longer it may take to pay off your debts. That’s where consolidation comes in. Combining several accounts into one makes it easier to keep up to date with your payments, can lower the amount of interest you’re responsible for, and can get you out of debt faster.
                </Typography>
            </div >
        </div >
    }

    return <div className={classes.responsiveWrapper}>
        <div className={classes.body}>
            <div className={classes.rowWrapper}>
                <Typography className={classes.header}>Our Client Commitment</Typography>
            </div>
            <div className={classes.rowWrapper} style={{ marginTop: '32px' }}>
                <div className={classes.columnWrapper}>
                    <img src={middleImage} className={classes.middlePic} alt="Man on phone." />
                </div>
                <div className={classes.columnWrapper}>
                    <Typography className={classes.bulletDescription}>
                        Our organization is full of dedicated and compassionate individuals focused on providing you with the best possible service. We strive for excellence every day so that we can make a difference in your financial life.
                    </Typography>

                    <div className={classes.checkboxesWrapper}>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Excellent client service representatives
                            </span>
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Best solutions for financial hardships
                            </span>
                        </div>
                  
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Referrals for friends and family members
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.rowWrapper}>
                <Typography className={classes.bottomText}>
                    Debt Consolidation Loans is here for you during your time of need. The more debt accounts you’re responsible for, the harder it is to keep track of your finances and the longer it may take to pay off your debts. That’s where consolidation comes in. Combining several accounts into one makes it easier to keep up to date with your payments, can lower the amount of interest you’re responsible for, and can get you out of debt faster.
                </Typography>
            </div>
        </div >
    </div >
}

export default OurClientCommitment;
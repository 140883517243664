import React, { useState } from 'react';
import { Theme, makeStyles, ThemeProvider } from "@material-ui/core";
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import ApplyBackgroundImage from '../static/apply/apply_image.jpg';
import FaqBackgroundImage from '../static/faq_header.png';
import HomeBackgroundImage from '../static/home/homepage_header.png';
import theme from '../themes/breakpoints';
import WhyChooseUsImage from '../static/why_header.png';
import LandingImage from '../static/landing/landing_header.png';

interface StyleProps {
    imageWrapper: BaseCSSProperties,
    homeBackgroundImage: BaseCSSProperties,
    headerImage: BaseCSSProperties,
    formHeadline: BaseCSSProperties,
    checkYourRateButton: BaseCSSProperties,
    checkYourRateArrow: BaseCSSProperties,
    debitAmountSelect: BaseCSSProperties,
    debitAmountSelectWrapper: BaseCSSProperties,
    phoneDiv: BaseCSSProperties,
}

interface MasterProps {
    home: StyleProps,
    apply: StyleProps,
    faqs: StyleProps,
    landing: StyleProps,
    whyChooseUs: StyleProps,
    thankYou: StyleProps,
}


const homeStyle: StyleProps = {


    imageWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        backgroundColor: '#a4b246',
        width: '100%',
    },
    headerImage: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
    homeBackgroundImage: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundSize:'cover',
        [theme.breakpoints.down('sm')]: {
            backgroundImage: 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(' + HomeBackgroundImage + ')',
            minHeight: '165px',
            maxWidth: '365',
            paddingTop: '30px',
        },
        [theme.breakpoints.only('md')]: {
            backgroundImage: 'url(' + HomeBackgroundImage + ')',
            height: '667px',
            maxWidth: '820px',
            paddingTop: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            backgroundImage: 'url(' + HomeBackgroundImage + ')',
            height: '760px',
            maxWidth: '1690px',
            paddingTop: '30px',
        },
    },
    phoneDiv: {
        alignSelf: 'center',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
        },
        [theme.breakpoints.only('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
        },
    },
    formHeadline: {
        fontFamily: 'OpenSans, Sans-serif',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            fontSize: '36px',
            color: '#ffffff',
            textAlign: 'center',
            textTransform: 'uppercase',
            marginLeft: '20px',
            marginRight: '20px',
        },
        [theme.breakpoints.only('md')]: {
            textTransform: 'uppercase',
            fontWeight: 600,
            width: '328px',
            height: '18px',
            marginTop: '31px',
            marginLeft: '43px',
            marginRight: '46px',
            fontSize: '17px',
            lineHeight: 1.21,
            color: '#2b6587',
            textAlign: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            textTransform: 'uppercase',
            fontWeight: 600,
            width: '428px',
            height: '23px',
            marginTop: '39px',
            marginLeft: '50px',
            marginRight: '50px',
            fontSize: '23px',
            lineHeight: 1.2,
            color: '#2b6587',
            textAlign: 'center',
        },
    },
    checkYourRateButton: {},
    checkYourRateArrow: {},
    debitAmountSelectWrapper: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: '18.5px',
            paddingLeft: '17px',
            paddingRight: '17px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '29px',
            marginLeft: '43px',
            marginRight: '41px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '39px',
            marginLeft: '54px',
            marginRight: '54px',
        },
    },
    debitAmountSelect: {
        borderRadius: '4px',
        backgroundColor: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '39px',
        },
        [theme.breakpoints.only('md')]: {
            width: '333px',
            height: '39px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '433px',
            height: '50px',
        },
    },
};

const applyStyle: StyleProps = {
    imageWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        backgroundColor: '#376485',
        width: '100%',
    },
    headerImage: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
    homeBackgroundImage: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.only('md')]: {
            justifyContent: 'start',
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'linear-gradient(to right, rgb(55, 100, 133, 1), rgb(55, 100, 133, 0), rgba(255,0,0,0), rgba(255,0,0,0)), url(' + ApplyBackgroundImage + ')',
            backgroundPosition: 'center',
            height: '667px',
            maxWidth: '820px',
            paddingTop: '35px',
            paddingLeft: '40px',
        },
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'start',
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'linear-gradient(to right, rgb(55, 100, 133, 1), rgb(55, 100, 133, 0), rgba(255,0,0,0), rgba(255,0,0,0)), url(' + ApplyBackgroundImage + ')',
            backgroundPosition: 'right',
            height: '792px',
            // maxWidth: '1690px',
            paddingTop: '75px',
            paddingLeft: '10%',
        },
    },
    phoneDiv: {
        alignSelf: 'center',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
        },
        [theme.breakpoints.only('md')]: {
            marginRight: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: '20px',
        },
    },
    formHeadline: {
        alignSelf: 'center',
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.58',
        letterSpacing: '0.13px',
        textAlign: 'center',
        color: '#2b6587',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            fontSize: '19px',
            color: '#2b6587',
            textAlign: 'center',
            // marginTop: '21px',
        },
        [theme.breakpoints.only('md')]: {
            textTransform: 'uppercase',
            fontWeight: 600,
            marginTop: '21px',
            marginBottom: '8px',
            marginLeft: '43px',
            marginRight: '46px',
            fontSize: '17px',
            lineHeight: 1.21,
            color: '#2b6587',
            textAlign: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            textTransform: 'uppercase',
            fontWeight: 600,
            marginTop: '39px',
            marginLeft: '50px',
            marginRight: '50px',
            fontSize: '23px',
            lineHeight: 1.2,
            color: '#2b6587',
            textAlign: 'center',
        },
    },
    checkYourRateButton: {},
    checkYourRateArrow: {},
    debitAmountSelectWrapper: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: '18.5px',
            paddingLeft: '17px',
            paddingRight: '17px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '19px',
            marginLeft: '43px',
            marginRight: '41px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '19px',
            marginLeft: '54px',
            marginRight: '54px',
        },
    },
    debitAmountSelect: {
        borderRadius: '4px',
        backgroundColor: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '39px',
        },
        [theme.breakpoints.only('md')]: {
            width: '333px',
            height: '39px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '433px',
            height: '50px',
        },
    },
};

const faqsStyle: StyleProps = {
    imageWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        backgroundColor: '#171f2a',
        width: '100%',
    },
    headerImage: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
    homeBackgroundImage: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'url(' + FaqBackgroundImage + ')',
            backgroundPosition: 'center',
            maxWidth: '100%',
            backgroundColor: '#f1f4f4',
            height: '153px',
        },
        [theme.breakpoints.only('md')]: {
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'url(' + FaqBackgroundImage + ')',
            backgroundPosition: 'center',
            height: '270px',
            maxWidth: '820px',
        },
        [theme.breakpoints.up('lg')]: {
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'url(' + FaqBackgroundImage + ')',
            backgroundPosition: 'right',
            height: '375px',
            maxWidth: '1690px',
        },
    },
    phoneDiv: {
        alignSelf: 'center',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
        },
        [theme.breakpoints.only('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
        },
    },
    formHeadline: {
        alignSelf: 'center',
        textAlign: 'center',
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '37px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '66px',
        },
    },
    checkYourRateButton: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#ffffff',
        alignSelf: 'center',
        borderRadius: '4px',
        backgroundColor: '#f27e20',
        [theme.breakpoints.down('sm')]: {
            width: '289px',
            height: '45px',
            fontSize: '18px',
            marginTop: '20px',
            lineHeight: '1.06px',
        },
        [theme.breakpoints.only('md')]: {
            width: '224px',
            height: '50px',
            fontSize: '18px',
            marginLeft: '17px',
            lineHeight: '19px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '250px',
            height: '50px',
            fontSize: '22px',
            lineHeight: '1.27px',
        },
    },
    checkYourRateArrow: {
        display: 'none',
    },
    debitAmountSelectWrapper: {
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingLeft: '17px',
            paddingRight: '17px',
        },
        [theme.breakpoints.only('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: '34px',
        },
    },
    debitAmountSelect: {
        borderRadius: '4px',
        backgroundColor: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '39px',
        },
        [theme.breakpoints.only('md')]: {
            width: '333px',
            height: '50px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '433px',
            height: '50px',
        },
    },
};

const whyChooseUsStyle: StyleProps = {
    imageWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        backgroundColor: '#a4b246',
        width: '100%',
    },
    headerImage: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
    homeBackgroundImage: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'url(' + WhyChooseUsImage + ')',
            backgroundPosition: 'center',
            maxWidth: '100%',
            backgroundColor: '#f1f4f4',
            height: '153px',
        },
        [theme.breakpoints.only('md')]: {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'url(' + WhyChooseUsImage + ')',
            backgroundPosition: 'center',
            height: '270px',
            maxWidth: '820px',
        },
        [theme.breakpoints.up('lg')]: {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'url(' + WhyChooseUsImage + ')',
            backgroundPosition: 'right',
            height: '575px',
            maxWidth: '1690px',
        },
    },
    phoneDiv: {
        alignSelf: 'center',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
        },
        [theme.breakpoints.only('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
        },
    },
    formHeadline: {
        alignSelf: 'center',
        textAlign: 'center',
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '37px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '66px',
        },
    },
    checkYourRateButton: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#ffffff',

        alignSelf: 'center',
        borderRadius: '4px',
        backgroundColor: '#f27e20',
        [theme.breakpoints.down('sm')]: {
            width: '289px',
            height: '45px',
            fontSize: '18px',
            marginTop: '20px',
        },
        [theme.breakpoints.only('md')]: {
            width: '224px',
            height: '50px',
            fontSize: '18px',
            marginLeft: '17px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '433px',
            height: '77px',
            fontSize: '30px',
        },
    },
    checkYourRateArrow: {
        display: 'none',
    },
    debitAmountSelectWrapper: {
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingLeft: '17px',
            paddingRight: '17px',
        },
        [theme.breakpoints.only('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: '34px',
        },
    },
    debitAmountSelect: {
        borderRadius: '4px',
        backgroundColor: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '39px',
        },
        [theme.breakpoints.only('md')]: {
            width: '333px',
            height: '50px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '433px',
            height: '50px',
        },
    },
};

const thankYouStyle: StyleProps = {
    imageWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        backgroundColor: '#a4b246',
        width: '100%',
    },
    headerImage: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
    homeBackgroundImage: {},
    phoneDiv: {
        alignSelf: 'center',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
        },
        [theme.breakpoints.only('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
        },
    },
    formHeadline: {},
    checkYourRateButton: {},
    checkYourRateArrow: {},
    debitAmountSelectWrapper: {},
    debitAmountSelect: {},
};

const landingStyle: StyleProps = {
    imageWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        backgroundColor: '#a4b246',
        width: '100%',
    },
    headerImage: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
    homeBackgroundImage: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'start',
        backgroundImage: 'url(' + LandingImage + ')',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            backgroundImage: 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(' + LandingImage + ')',
            backgroundSize: '800px',
            minHeight: '283px',
            height: '365',
        },
        [theme.breakpoints.only('md')]: {
            maxHeight: '419px',
            height: '820px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '662px',
            maxWidth: '1690px',
        },
    },
    phoneDiv: {
        alignSelf: 'center',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
        },
        [theme.breakpoints.only('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
        },
    },
    formHeadline: {
    },
    checkYourRateButton: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#ffffff',

        alignSelf: 'center',
        borderRadius: '4px',
        backgroundColor: '#f27e20',
        [theme.breakpoints.down('sm')]: {
            width: '270px',
            height: '47px',
            marginTop: '22px',
            fontSize: '21px',
            lineHeight: '1.52',
            marginBottom: '58px',
        },
        [theme.breakpoints.only('md')]: {
            width: '275px',
            height: '50px',
            marginTop: '33px',
            fontSize: '18px',
            lineHeight: '1.14',
        },
        [theme.breakpoints.up('lg')]: {
            width: '433px',
            height: '77px',
            marginTop: '51px',
            fontSize: '30px',
            lineHeight: '0.93',
        },
    },
    checkYourRateArrow: {
        [theme.breakpoints.down('sm')]: {
            width: '30px',
            height: '30px',
        },
        [theme.breakpoints.only('md')]: {
            marginLeft: '17px',
            width: '30px',
            height: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '13px',
            width: '46px',
            height: '46px',
        },
    },
    debitAmountSelect: {},
    debitAmountSelectWrapper: {},
};

const masterStyle: MasterProps = {
    home: homeStyle,
    apply: applyStyle,
    faqs: faqsStyle,
    whyChooseUs: whyChooseUsStyle,
    thankYou: thankYouStyle,
    landing: landingStyle,
};

type PropsClasses = Record<keyof StyleProps, string>

export const StylesContext = React.createContext((themeName: string): void => { });
export const ClassesContext = React.createContext({} as PropsClasses);

const StylesProvider: React.FC = (props) => {
    const [themeName, setThemeName] = useState('');

    const useStyles = makeStyles<Theme, StyleProps>(() => {
        if (themeName === 'home' || themeName === 'apply' || themeName === 'faqs' || themeName === 'landing' || themeName === 'whyChooseUs' || themeName === 'thankYou') {
            return masterStyle[themeName] as any
        }
    });

    const classes: PropsClasses = useStyles({} as StyleProps);

    return (
        <ThemeProvider theme={theme}>
            <StylesContext.Provider value={setThemeName}>
                <ClassesContext.Provider value={classes}>
                    {props.children}
                </ClassesContext.Provider>
            </StylesContext.Provider>
        </ThemeProvider>
    );
}

export default StylesProvider;
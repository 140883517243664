import { createMuiTheme } from '@material-ui/core/styles';

const breakpoints = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 0,
            md: 420,
            lg: 820,
            xl: 1620,
        },
    },
});

export default breakpoints
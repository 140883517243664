import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button, withStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    arrow: {
        [theme.breakpoints.down('sm')]: {
            width: '30px',
            height: '30px',
        },
        [theme.breakpoints.only('md')]: {
            marginLeft: '13px',
            width: '46px',
            height: '46px',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '13px',
            width: '46px',
            height: '46px',
        },
    },
    button: {
        borderRadius: '4px',
        backgroundBlendMode: 'overlay, ',
        backgroundColor: '#f27e20',
        margin: 'auto',
        marginTop: '17px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '17px',
            width: '100%',
            height: '47px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '17px',
            width: '333px',
            height: '60px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '17px',
            width: '333px',
            height: '60px',
        },
    },
    buttonText: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#fffff',
        [theme.breakpoints.down('sm')]: {
            fontSize: '21px',
            lineHeight: '1.33',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '30px',
            lineHeight: '0.93',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '30px',
            lineHeight: '0.93',
        },
    },
}));

const StyledButton = withStyles(theme => ({
    root: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            fontSize: '21px',
            lineHeight: '0.93',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '24px',
            lineHeight: '0.93',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '30px',
            lineHeight: '0.93',
        },
    },
}))(Button);

type Props = {
    label?: string,
    handleSubmit: () => void,
}

const CCPAFormButton= ({ label, handleSubmit }: Props) => {
    const classes = useStyles();

    return <StyledButton
        className={classes.button}
        variant="contained"
    
        onClick={handleSubmit}
    >
        SUBMIT
    </StyledButton>
}

export default CCPAFormButton;
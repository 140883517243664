import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import phoneIcon from '../static/phone_icon_thankyou.png';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#ffffff',
        },
        [theme.breakpoints.only('md')]: {
            backgroundColor: '#ffffff',
        },
        [theme.breakpoints.up('lg')]: {
            backgroundColor: '#ffffff',
        },
    },
    body: {
        maxWidth: '100%',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
            paddingLeft: '17px',
            paddingRight: '0px',
            paddingTop: '27px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '30px',
            paddingLeft: '53px',
            paddingRight: '23px',
            paddingTop: '85px',
            paddingBottom: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '45px',
            paddingRight: '25px',
            paddingTop: '85px',
            paddingBottom: '80px',
            maxWidth: '1170px',
            fontSize: '50px',
        },
    },
    header: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2b6587',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '50px',
        },
    },
    bulletWrapper: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '15px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '48px',
        },
        [theme.breakpoints.only('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
        },
    },
    bulletCheckmark: {
        alignSelf: 'center',
        width: '31px',
        height: '30px',
    },
    copy: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#5f5e5e',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '24px',
        },
    },
    bulletDescription: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: '0.6px',
        textAlign: 'justify',
        marginRight: '17px',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '24px',
        },
    },
    bulletText: {
        alignSelf: 'center',
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 600,
        textAlign: 'left',
        width: '100%',
        color: '#585859',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '15px',
        },
        [theme.breakpoints.only('md')]: {
            paddingLeft: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '24px',
        },
    },
    columnWrapper: {
        display: 'flex',
        justifyContent: "start",
        flexDirection: 'column',
    },
    rowWrapper: {
        display: 'flex',
        justifyContent: "space-between",
        flexDirection: 'row',
    },
    bottomWrapper: {
        display: 'flex',
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            marginTop: '29px',
            marginBottom: '33px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '50px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '50px',
        },
    },
    top: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            marginTop: '24px',
            paddingRight: '17px',
            fontSize: '16px',
            lineHeight: '1.13',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '30px',
            marginRight: '30px',
            fontSize: '15px',
            lineHeight: '1.37',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '45px',
            marginRight: '30px',
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    middleText: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            marginTop: '24px',
            marginBottom: '26px',
            paddingRight: '17px',
            fontSize: '18px',
            lineHeight: '1.06',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '20px',
            marginBottom: '24px',
            fontSize: '15px',
            lineHeight: '1.53',
            marginRight: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '32px',
            marginBottom: '24px',
            marginRight: '30px',
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    conclusion: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            marginRight: '17px',
            fontSize: '16px',
            lineHeight: '1.13',
        },
        [theme.breakpoints.only('md')]: {
            width: '456px',
            fontSize: '15px',
            lineHeight: '1.37',
        },
        [theme.breakpoints.up('lg')]: {
            width: '785px',
            height: '117px',
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    middlePic: {
        [theme.breakpoints.down('sm')]: {
            marginRight: '11px',
            width: '55px',
            height: '55px',
        },
        [theme.breakpoints.only('md')]: {
            marginRight: '19px',
            width: '100%',
            maxWidth: '92px',
            height: 'auto',
            objectFit: 'contain',
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: '30px',
            float: 'right',
            width: '121px',
            height: '121px',
        },
    },
    dearUserWrapper: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '19px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '46px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '46px',
        },
    },
    copyWrapper: {
        marginTop: '20px'
    },
    horizontalLine: {
        marginTop: '34px',
        height: '3px',
        width: '100%',
    },
    callUsNow: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.67',
        letterSpacing: 'normal',
        color: '#064c75',
        marginRight: '17px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '1.03',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '27px',
            lineHeight: '1.03',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '36px',
            lineHeight: '1.43',
        },
    },
    phoneNumber: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: '1.12px',
        color: '#157ebc',
        marginRight: '17px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px',
            lineHeight: '1.43',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '42px',
            lineHeight: '1.03',
        },
        [theme.breakpoints.up('lg')]: {
            lineHeight: '1.43',
            fontSize: '56px',
        },
    },
}));

type Props = {
    phone: string;
    firstName: string;
}

const Congratulations = ({phone, firstName} : Props) => {
    const classes = useStyles();
 
    
    return <div className={classes.responsiveWrapper}>
        <div className={classes.body}>
            <div>
                <Typography className={classes.header}>Congratulations!</Typography>
            </div>
            <div className={classes.columnWrapper}>
                <Typography className={classes.bulletDescription + ' ' + classes.dearUserWrapper}>
Dear {firstName?firstName:null},
                </Typography>
                <Typography className={classes.bulletDescription + ' ' + classes.copyWrapper}>
                    You’re one step closer to financial independence and a life free from debt! One of our Certified Debt Specialists will reach out to you shortly to complete your free Debt Analysis, answer any questions you may have, and provide a recommendation on the best solution specific to your situation. If you’d like to speak to us immediately, feel free to call us at {phone} during our hours of operation. During our first call, we’ll focus on collecting the necessary information in order to complete your Debt Analysis.
                </Typography>
            </div>
            <div className={classes.bottomWrapper}>
                <img src={phoneIcon} className={classes.middlePic} alt="Phone in a circle." />
                <div>
                    <Typography className={classes.callUsNow}>
                        CALL US NOW TO GET STARTED
                    </Typography>
                    <Typography className={classes.phoneNumber}>
                        {phone}
                    </Typography>
                </div>
            </div>
        </div >
    </div >
}

export default Congratulations;
import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button, withStyles } from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
    button: {
        alignSelf: 'center',
        width: '120px',
        height: '40px',
        borderRadius: '3px',
        backgroundColor: '#f27e20',
    },
}));

const StyledButton = withStyles(theme => ({
    root: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#ffffff',
        paddingLeft: '5px',
        paddingRight: '5px',
        [theme.breakpoints.down('sm')]: {
            height: '40px',
            fontSize: '15px',
            lineHeight: '1.6',
        },
        [theme.breakpoints.only('md')]: {
            height: '34px',
            width: '98px',
            fontSize: '12px',
            lineHeight: '1.23',
        },
        [theme.breakpoints.up('lg')]: {
            height: '40px',
            fontSize: '15px',
            lineHeight: '1.2',
        },
    },
}))(Button);

const CheckRateButton = () => {
    const classes = useStyles();
    const history = useHistory();

    const handleApplyClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        history.push("/apply");
    }

    return <StyledButton
        className={classes.button}
        variant="contained"
        onClick={handleApplyClick} 
    >
        Check Rate
    </StyledButton>
}

export default CheckRateButton
import React from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sliderLabel: {
            color: '#f27e20',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '10px',
            },
            [theme.breakpoints.only('md')]: {
                marginBottom: '15px',
            },
            [theme.breakpoints.up('lg')]: {
                marginBottom: '20px',
            },
        },
        sliderValue: {
            fontFamily: 'OpenSans, Sans-serif',
            fontSize: '32px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            textAlign: 'center',
            color: '#f27e20',
            borderRadius: '13.5px',
            border: 'solid 1px #ffffff',
            backgroundColor: '#157ebc',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '287px',
                marginRight: '45px',
                marginLeft: '45px',
            },
            [theme.breakpoints.only('md')]: {
                maxWidth: '355px',
                marginRight: '55px',
                marginLeft: '55px',
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: '589px',
                marginRight: '55px',
                marginLeft: '55px',
            },
        }
    }),
);

const marks = [
    {
        value: 0,
        label: '$1,000',
    },
    {
        value: 100000,
        label: '$100,000+',
    },
];
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = withStyles(theme => ({
    root: {
        color: '#3880ff',
        height: 2,
        [theme.breakpoints.down('sm')]: {
            padding: '7px 0',
        },
        [theme.breakpoints.only('md')]: {
            padding: '9px 0',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '13px 0',
        },
    },
    thumb: {
        height: 36,
        width: 36,
        backgroundColor: '#fff',
        boxShadow: iOSBoxShadow,
        marginTop: -18,
        marginLeft: -14,
        marginRight: 14,
        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
        border: '12px solid #f27e20',
    },
    active: {},
    track: {
        height: 0,
    },
    rail: {
        display: 'none',
    },
    marked: {
        borderRadius: '13.5px',
        border: 'solid 1px #ffffff',
        backgroundColor: '#157ebc',
    },
    valueLabel: { // this is the orange label above the marker it change as you slide the slider
        '& *': {
            background: 'transparent',
        },
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '32px',
        },
    },
    markLabel: { // labels for each of the marks
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            marginTop: '4px',
            marginBottom: '10px',
            fontSize: '12px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '15px',
            fontSize: '13.5px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '15px',
            fontSize: '21px',
        },

    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
}))(Slider);

type Props = {
    className?: string;
    debitAmount: number;
    setDebitAmount: (debitAmount: number) => void
}

const MySlider = ({ className, debitAmount, setDebitAmount }: Props) => {
    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<{}>, value: number | number[]) => {
        setDebitAmount(Array.isArray(value) ? value[0] : value);
    }

    return <IOSSlider
        valueLabelDisplay="on"
        value={debitAmount}
        min={1000}
        step={1000}
        max={100000}
        marks={marks}
        className={classes.sliderValue}
        valueLabelFormat={value => {
            let displayValue =  '$100,000+';

            if (value !== 100000) {
                displayValue = formatter.format(value).replace(/\D00(?=\D*$)/, '');
            }

            return <div className={classes.sliderLabel}>
                {displayValue}
            </div>
        }}
        onChangeCommitted={handleChange}
    />;
}

export default MySlider;
import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import middleImage from '../static/understanding_options_image.png';
import checkmark from '../static/home/checkmark.png';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#ffffff',
        },
        [theme.breakpoints.only('md')]: {
            backgroundColor: '#ffffff',
        },
        [theme.breakpoints.up('lg')]: {
            backgroundColor: '#ffffff',
        },
    },
    body: {
        maxWidth: '100%',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
            paddingLeft: '0px',
            paddingRight: '0px',
            paddingTop: '27px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '30px',
            paddingLeft: '53px',
            paddingRight: '23px',
            paddingTop: '85px',
            paddingBottom: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '45px',
            paddingRight: '25px',
            paddingTop: '85px',
            paddingBottom: '80px',
            maxWidth: '1170px',
            // width: '95%',
            fontSize: '50px',
        },
    },
    header: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2b6587',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
            textAlign: 'center',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '50px',
        },
    },
    bulletWrapper: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '15px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '48px',
        },
    },
    bulletCheckmark: {
        alignSelf: 'center',
        width: '31px',
        height: '30px',
    },
    bulletDescription: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        textAlign: 'left',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
            lineHeight: '1.33',
            letterSpacing: '0.6px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '14px',
            lineHeight: '1.36',
            letterSpacing: '0.35px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '24px',
            lineHeight: '1.33',
            letterSpacing: '0.6px',
        },
    },
    bulletText: {
        alignSelf: 'center',
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 600,
        textAlign: 'left',
        width: '100%',
        color: '#585859',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '15px',
        },
        [theme.breakpoints.only('md')]: {
            paddingLeft: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '24px',
        },
    },
    columnWrapper: {
        display: 'flex',
        justifyContent: "start",
        flexDirection: 'column',
        width: '50%',
    },
    rowWrapper: {
        display: 'flex',
        justifyContent: "space-between",
        flexDirection: 'row',
    },
    bottomWrapper: {
        marginTop: '5px',
        display: 'flex',
        justifyContent: "space-between",
    },
    checkboxesWrapper: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '17px',
            fontSize: '16px',
            lineHeight: '1.13',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '25px',
            fontSize: '15px',
            lineHeight: '1.37',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '45px',
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    middleText: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            marginTop: '27px',
            marginBottom: '32px',
            paddingRight: '15px',
            fontSize: '18px',
            lineHeight: '1.2',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '20px',
            marginBottom: '24px',
            fontSize: '15px',
            lineHeight: '1.53',
            marginRight: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '32px',
            marginBottom: '24px',
            marginRight: '30px',
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    conclusion: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            marginRight: '17px',
            fontSize: '16px',
            lineHeight: '1.13',
        },
        [theme.breakpoints.only('md')]: {
            width: '456px',
            fontSize: '15px',
            lineHeight: '1.37',
        },
        [theme.breakpoints.up('lg')]: {
            width: '785px',
            height: '117px',
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    middlePic: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '23px',
            width: '100%',
            maxWidth: '100%',
            height: '193px',
            objectFit: 'cover',
        },
        [theme.breakpoints.only('md')]: {
            float: 'right',
            maxWidth: '100%',
            height: 'auto',
        },
        [theme.breakpoints.up('lg')]: {
            float: 'right',
            maxWidth: '100%',
            height: 'auto',
        },
    },
    horizontalLine: {
        marginTop: '34px',
        height: '3px',
        width: '100%',
    },
}));

const UnderstandingYourOptions = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (isMobile) {
        return <div className={classes.responsiveWrapper}>
            <div className={classes.body}>
                <div className={classes.header}>Understanding Your Options</div>
                <div style={{ paddingLeft: '17px',}}>
                    <img src={middleImage} className={classes.middlePic} alt="Woman looking at laptop." />
                    <div className={classes.middleText}>
                        The Debt Consolidation Loans program covers a majority of unsecured consumer debt. We see a large amount of clients carrying significant credit card balances, and our teams are experts when it comes to negotiating with the major credit card companies and banks. We can help you consolidate:
                    </div>
                    <div className={classes.checkboxesWrapper}>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Credit Cards
                            </span>
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Personal loans and lines of credit
                            </span>
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Medical bills
                            </span>
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Collections and repossessions
                            </span>
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Business debts
                            </span>
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Certain student debts
                            </span>
                        </div>
                    </div>
                </div>
            </div >
        </div >

    }

    return <div className={classes.responsiveWrapper}>
        <div className={classes.body}>
            <div className={classes.rowWrapper}>
                <Typography className={classes.header}>Understanding Your Options</Typography>
            </div>
            <div className={classes.rowWrapper} style={{ marginTop: '32px' }}>
                <div className={classes.columnWrapper}>
                    <img src={middleImage} className={classes.middlePic} alt="Woman looking at laptop." />
                </div>
                <div className={classes.columnWrapper}>
                    <Typography className={classes.bulletDescription}>
                        The Debt Consolidation Loans program covers a majority of unsecured consumer debt. We see a large amount of clients carrying significant credit card balances, and our teams are experts when it comes to negotiating with the major credit card companies and banks. We can help you consolidate:
                    </Typography>

                    <div className={classes.checkboxesWrapper}>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Credit Cards
                            </span>
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Personal loans and lines of credit
                            </span>
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Medical bills
                            </span>
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Collections and repossessions
                            </span>
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Business debts
                            </span>
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Certain student debts
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </div >
}

export default UnderstandingYourOptions;
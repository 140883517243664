import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import middleImage from '../static/home/what_is_dc_image.png';
import checkmark from '../static/home/checkmark.png';
import ApplyNowButton from './ApplyNowButton';
import { useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#ffffff',
        },
        [theme.breakpoints.only('md')]: {
            backgroundColor: '#f6f6f6',
        },
        [theme.breakpoints.up('lg')]: {
            backgroundColor: '#f6f6f6',
        },
    },
    body: {
        maxWidth: '100%',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
            paddingLeft: '17px',
            paddingRight: '0px',
            paddingTop: '27px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '30px',
            paddingLeft: '53px',
            paddingRight: '23px',
            paddingTop: '85px',
            paddingBottom: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '50px',
            paddingTop: '85px',
            maxWidth: '1170px',
            width: '95%',
        },
    },
    header: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2b6587',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '50px',
        },
    },
    bulletWrapper: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '15px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '48px',
        },
        [theme.breakpoints.only('md')]: {
            paddingLeft: '89px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '89px',
        },
    },
    bulletCheckmark: {
        alignSelf: 'center',
        width: '31px',
        height: '30px',
    },
    bulletText: {
        alignSelf: 'center',
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 600,
        textAlign: 'left',
        width: '100%',
        color: '#585859',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '15px',
        },
        [theme.breakpoints.only('md')]: {
            paddingLeft: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '24px',
        },
    },
    columnWrapper: {
        display: 'flex',
        justifyContent: "start",
        flexDirection: 'column',
    },
    nonMobileBottom: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            marginTop: '24px',
            paddingRight: '17px',
            fontSize: '16px',
            lineHeight: '1.13',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '30px',
            marginRight: '30px',
            fontSize: '15px',
            lineHeight: '1.37',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '37px',
            marginRight: '30px',
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    rowWrapper: {
        display: 'flex',
        justifyContent: "space-between",
        flexDirection: 'row',
    },
    bottomWrapper: {
        marginTop: '5px',
        display: 'flex',
        justifyContent: "space-between",
    },
    topSection: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            marginTop: '24px',
            paddingRight: '17px',
            fontSize: '16px',
            lineHeight: '1.13',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '30px',
            marginRight: '30px',
            fontSize: '15px',
            lineHeight: '1.37',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '45px',
            marginRight: '30px',
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    middleText: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            marginTop: '24px',
            marginBottom: '26px',
            paddingRight: '17px',
            fontSize: '18px',
            lineHeight: '1.06',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '20px',
            marginBottom: '24px',
            fontSize: '15px',
            lineHeight: '1.53',
            marginRight: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '32px',
            marginBottom: '24px',
            marginRight: '30px',
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    conclusion: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#585859',
        [theme.breakpoints.down('sm')]: {
            marginRight: '17px',
            fontSize: '16px',
            lineHeight: '1.13',
        },
        [theme.breakpoints.only('md')]: {
            width: '456px',
            fontSize: '15px',
            lineHeight: '1.37',
        },
        [theme.breakpoints.up('lg')]: {
            width: '785px',
            fontSize: '24px',
            lineHeight: '1.33',
            marginBottom: '82px',
        },
    },
    middlePic: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '23px',
            width: '100%',
            maxWidth: '330px',
        },
        [theme.breakpoints.only('md')]: {
            paddingLeft: '45px',
            width: '50%',
            float: 'right',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '45px',
            width: '50%',
            float: 'right',
        },
    },
    horizontalLine: {
        marginTop: '34px',
        height: '3px',
        width: '100%',
    },
}));

const WhatIsDebtConsolidation = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (isMobile) {
        return <div className={classes.body}>
            <div className={classes.header}>What is Debt Consolidation?</div>
            <div className={classes.topSection}>
                Debt consolidation is the process of taking multiple credit accounts - to which you are currently submitting a monthly amount to pay down the balance you owe - and consolidating all of those into one, easy-to-manage account and monthly payment.
                <img src={middleImage} className={classes.middlePic} alt="Woman looking at laptop." />
            </div>
            <div className={classes.middleText}>
                Borrowers may consolidate debt for these reasons:
            </div>
            <div className={classes.bulletWrapper}>
                <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                <span className={classes.bulletText}>
                    Lower APR
                </span>
            </div>
            <div className={classes.bulletWrapper}>
                <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                <span className={classes.bulletText}>
                    Fewer bills to track
                </span>
            </div>
            <div className={classes.bulletWrapper}>
                <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                <span className={classes.bulletText}>
                    Faster debt repayment
                </span>
            </div>
            <div className={classes.conclusion}>
                The more debts you have, the more difficult it may be to stay on top of your finances. Consolidating debt helps you keep track of what you owe. Ther’s also the possibility of lower interest rates and payment anounts than you currently pay.
            </div>

            <ApplyNowButton />

            {isMobile && <hr className={classes.horizontalLine} />}
        </div >

    }

    return <div className={classes.responsiveWrapper}>
        <div className={classes.body}>
            <div className={classes.header}>What is Debt Consolidation?</div>
            <div className={classes.rowWrapper}>
                <div className={classes.columnWrapper}>
                    <div className={classes.topSection}>
                        <img src={middleImage} className={classes.middlePic} alt="Woman looking at laptop." />
                        Many individuals struggling with debt have multiple credit accounts with different balances. Making separate payments to multiple accounts each month can be difficult, overwhelming, and expensive. Debt consolidation is the process of combining these accounts into one single account and monthly payment.
                        <div className={classes.middleText}>
                        Consumers may consolidate debt for many reasons, including the possibility of:
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Lower interest rates
                            </span>
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Fewer bills to track
                            </span>
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Faster debt repayment
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.rowWrapper + ' ' + classes.nonMobileBottom}>
                <div className={classes.conclusion}>
                    The more accounts with debt you have, the more difficult it is to stay on top of your overall financial health. Consolidating debt helps you keep track of what you owe. There’s also the possibility of lower interest rates and payment amounts than you currently pay.
                </div>

                <ApplyNowButton />
            </div>
        </div>
    </div>
}

export default WhatIsDebtConsolidation;
import React, { useContext } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Accordion, AccordionDetails, AccordionSummary, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ClassesContext } from './StylesProvider';

const useStyles = makeStyles(theme => ({
    responsiveWrapper: {
    },
    bodyWrapper: {
        alignSelf: 'center',
        width: '100%',
        backgroundColor: '#034069',
    },
    body: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#034069',
        width: '100%',
    },
    container: {
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
        },
        [theme.breakpoints.only('md')]: {
            padding: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '60px',
            padding: '40px',
            maxWidth: '1170px',
        },
    },
    subHeader: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginTop: '31px',
            fontSize: '22px',
        },
        [theme.breakpoints.only('md')]: {
            textAlign: 'left',
            marginTop: '29px',
            fontSize: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            textAlign: 'left',
            fontSize: '40px',
        },
    },
    subCopy: {
        marginTop: '34px',
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '24px',
        },
    },
    faqsList: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '35px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '66px',
        },
    },
    heading: {
        marginLeft: '10px',
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#000000',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '21px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '21px',
        },
    },
    description: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 300,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#000000',
        marginTop: '10px',
        marginLeft: '30px',
        marginRight: '30px',
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '15px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '18px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '18px',
        },
    },
    panelSummary: {
        flexDirection: "row-reverse",
        borderBottom: '.5px solid #000',
        paddingLeft: '0px',
    }
}));

const accordianConfig = [
    {
        title: 'What is debt consolidation?',
        description: 'Debt consolidation is a financial strategy in which you consolidate your debts into one easy to manage account and monthly payment. While there are various methods of consolidation, the most common is a debt consolidation loan.',
    },
    {
        title: 'Can I consolidate all of my accounts?',
        description: 'Typically, only unsecured debts can be consolidated. Unsecured debts include credit card debt, personal loan debt, medical debt, and even some types of student loan debt. Secured debts include debts that are secured by collateral such as a mortgage.',
    },
    {
        title: 'Why do people consolidate their debt?',
        description: 'When you have several different debts, it can be hard to keep track of different payment dates and interest rates. By consolidating their debt, people are able to combine all of those debts into one easy to manage account and monthly payment, making it harder to miss a payment and easier to keep track of their debt level.',
    },
    {
        title: 'What\'s the difference between secured and unsecured debt?',
        description: 'A secured debt is one that is tied to some sort of collateral, such as a mortgage loan secured by a home or an auto loan secured by a car. Unsecured debt is not tied to any type of collateral. Examples include credit card debt, personal loan debt, and even some types of medical debt.',
    },
    {
        title: 'Can I use a balance transfer card to deal with my outstanding debts?',
        description: 'When using a balance transfer card, you take multiple creditor accounts and transfer their amounts onto one card, which then carries the full balance of your debts. While this method of debt consolidation can be effective, it’s important to keep in mind the interest rate of your card and how long it will take you to pay it off.',
    },
];

const Faqs = () => {
    const classes = useStyles();
    const themedClasses = useContext(ClassesContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const faqList = accordianConfig.map((config, index) => {
        return <Accordion
            key={`faq-item-${index}`}
            defaultExpanded={true}
            style={{ marginTop: '13px' }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${index}-content`}
                id={`panel-${index}-header`}
                className={classes.panelSummary}
            >
                <Typography className={classes.heading}>{config.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography className={classes.description}>
                    {config.description}
                </Typography>
            </AccordionDetails>
        </Accordion>
    })

    return <div className={classes.responsiveWrapper}>
        <div className={themedClasses.imageWrapper}>
            <div className={themedClasses.headerImage}>
                <div className={themedClasses.homeBackgroundImage}>
                    <Typography className={themedClasses.formHeadline}>Frequently Asked Questions</Typography>
                </div>
            </div>
        </div>
        <div className={classes.bodyWrapper}>
            <div className={classes.body}>
                <div className={classes.container}>
                    <Typography className={classes.subHeader}>FAQs About Debt {isMobile ? <br></br> : ''}Consolidation Loans</Typography>
                    <Typography className={classes.subCopy}>To help you get a better idea of what our program can accomplish, take a look at some of our most commonly asked questions.</Typography>
                    <div className={classes.faqsList}>
                        {faqList}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Faqs;
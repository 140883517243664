import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Container } from "@material-ui/core";
import { StylesContext } from "../components/StylesProvider";
import Header from "../components/Header";
import Footer from "../components/Footer";
import defaultData from "../defaultData.js";
import WhyUseDebtConsolidation from "../components/WhyUseDebtConsolidation";
import LookingForDebtConsolidationLoan from "../components/LookingForDebtConsolidationLoan";
import UnderstandingYourOptions from "../components/UnderstandingYourOptions";
import HowItWorksImages from "../components/HowItWorksImages";

const WhyChooseUs = ({ match }: RouteComponentProps<any>) => {
  const setThemeName = useContext(StylesContext);
  setThemeName("whyChooseUs");

  return (
    <React.Fragment>
      <Container
        maxWidth={false}
        disableGutters
        style={{ backgroundColor: "#ffffff" }}
      >
        <Header
          phoneNumber={defaultData.phoneNumber}
          showPhone={true}
          showWhyChooseUs={true}
          showFaqs={true}
          showCheckRates={true}
        />
        <WhyUseDebtConsolidation />
        <LookingForDebtConsolidationLoan />
        <HowItWorksImages />
        <UnderstandingYourOptions />
        <Footer
          addressLine1={defaultData.addressLine1}
          addressLine2={defaultData.addressLine2}
          copyright={defaultData.copyright}
          email={defaultData.email}
        />
      </Container>
    </React.Fragment>
  );
};

export default WhyChooseUs;

import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

const useStyles = makeStyles(theme => ({
    error: {
        backgroundColor: '#ffc3c3',
        [theme.breakpoints.down('sm')]: {
            height: '39px',
        },
        [theme.breakpoints.only('md')]: {
            height: '39px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '50px',
        },
    },
    rawInput: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#ffffff',
            height: '39px',
        },
        [theme.breakpoints.only('md')]: {
            height: '39px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '50px',
        },
    },
    input: {
        '&::placeholder': {
            fontFamily: 'ProximaNova, Sans-serif',
            fontWeight: '600',
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            overflow: 'visible',
            color: '#717171 !important',
            opacity: 1,
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '1.75',
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '16px',
                lineHeight: '1.33',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '21px',
                lineHeight: '1.33',
            },
        },
    }
}));

type Props = {
    label: string;
    value: string;
    id: string;
    setValue: (value: string) => void;
    handleSubmit: () => void;
    mask?: string;
    error?: boolean;
    className?: string;
}

const FormInput = ({ label, id, value, setValue, handleSubmit, mask, error, className }: Props) => {
    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    if (mask !== undefined) {
        return <InputMask
            onChange={handleChange}
            mask={mask}
            value={value}
            maskChar=" "
        >
            {() => <TextField
                className={className}
                id={id}
                placeholder={label}
                variant="outlined"
                error={error}
                InputProps={{
                    className: error ? classes.error : classes.rawInput,
                    classes: {
                        input: classes.input
                    },
                }}
                InputLabelProps={{ disableAnimation: true }}
            />}
        </InputMask>
    }

    return <TextField
        value={value}
        onChange={handleChange}
        className={className}
        id={id}
        placeholder={label}
        variant="outlined"
        error={error}
        onKeyDown={handleKeyDown} 
        InputProps={{
            className: error ? classes.error : classes.rawInput,
            classes: {
                input: classes.input
            },
        }}
    />
}

export default FormInput
import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormSelect from './FormSelect';
import { Typography } from '@material-ui/core';
import CheckYourRate from '../components/CheckYourRate';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    bodyWrapper: {
        alignSelf: 'center',
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        width: '100%',
    },
    container: {
        alignSelf: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
        },
        [theme.breakpoints.only('md')]: {
            padding: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '40px',
            maxWidth: '1170px',
        },
    },
    body: {
        alignSelf: 'center',
        boxShadow: '0 0 10px #999999',
        borderRadius: '4px',
        border: 'solid 3px #ffffff',
        backgroundColor: '#f9f9f9',

        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
        },
        [theme.breakpoints.only('md')]: {
            paddingTop: '50px',
            paddingBottom: '43px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '25px',
            marginBottom: '26px',
            height: '172px',
            maxWidth: '1170px',
        },
    },
    header: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#2b6587',
        [theme.breakpoints.down('sm')]: {
            marginTop: '23px',
            fontSize: '20px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '20px',
            fontSize: '40px',
        },
    },
    row: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: '13px',
            marginBottom: '20px',
            flexDirection: 'column',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '24px',
            flexDirection: 'row',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '24px',
            flexDirection: 'row',
        },
    },
}));

type Props = {
    debitAmount: string;
    setDebitAmount: (debitAmount: string) => void;
}

const debitAmountToBaseNumber = (debitAmount: string): number => {
    if (debitAmount === '0 - $4,999') {
        return 0;
    } else if (debitAmount === '$5,000 - $7,499') {
        return 5000;
    } else if (debitAmount === '$7,500 - $9,999') {
        return 75000;
    } else if (debitAmount === '$10,000 - $14,999') {
        return 10000;
    } else if (debitAmount === '$15,000 - $19,999') {
        return 15000;
    } else if (debitAmount === '$20,000 - $29,999') {
        return 20000;
    } else if (debitAmount === '$30,000 - $39,999') {
        return 30000;
    } else if (debitAmount === '$40,000 - $49,999') {
        return 40000;
    } else if (debitAmount === '$50,000 - $59,999') {
        return 50000;
    } else if (debitAmount === '$60,000 - $69,999') {
        return 60000;
    } else if (debitAmount === '$70,000 - $79,999') {
        return 70000;
    } else if (debitAmount === '$80,000 - $89,999') {
        return 80000;
    } else if (debitAmount === '$90,000 - $99,999') {
        return 90000;
    }

    return 100000;
}
const DoYouQualifyInline = ({ debitAmount, setDebitAmount, }: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const [error, setError] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!debitAmount) {
            setError(true);
        } else {
            history.push("/apply/" + debitAmountToBaseNumber(debitAmount));
        }
    }

    return <div className={classes.responsiveWrapper}>
        <div className={classes.bodyWrapper}>
            <div className={classes.container}>
                <div className={classes.body}>
                    <Typography className={classes.header}>Do You Qualify for Debt Consolidation?</Typography>
                    <div className={classes.row}>
                        <FormSelect
                            id="debitAmount"
                            label="debitAmount"
                            value={debitAmount}
                            error={error}
                            setValue={setDebitAmount}
                        />
                        <CheckYourRate
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default DoYouQualifyInline;
import React, { useContext, useState } from "react";
import validator from "validator";
import { Container } from "@material-ui/core";
import AccreditedBar from "../components/AccreditedBar";
import WhatIsDebtConsolidation from "../components/WhatIsDebtConsolidation";
import HowItWorks from "../components/HowItWorks";
import IsDebtConsolodationRightForMe from "../components/IsDebtConsolodationRightForMe";
import Benefits from "../components/Benefits";
import DoYouQualify from "../components/DoYouQualify";
import { StylesContext } from "../components/StylesProvider";
import Header from "../components/Header";
import Footer from "../components/Footer";
import defaultData from "../defaultData.js";
import applySubmit from "../api/apply-api.js";

import { useHistory } from "react-router-dom";

export type FormErrors = {
  debitAmount: boolean;
  firstName: boolean;
  lastName: boolean;
  phone: boolean;
  email: boolean;
};

const Homepage: React.FC = () => {
  const setThemeName = useContext(StylesContext);
  setThemeName("home");

  const [debitAmount, setDebitAmount] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState<FormErrors>({
    debitAmount: false,
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
  });
  const history = useHistory();
  const submitForm = async (body: string) => {
    const submitResponse = await applySubmit(body);

    // @ts-ignore
    if (submitResponse.status === 200) {
      console.log(submitResponse);
      history.push("/thankyou", { firstName: firstName });
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      var body = JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
        email_address: email,
        debt_amount: debitAmount,
        cookie_enabled: navigator.cookieEnabled,
        url_of_page: window.location.href,
        web_referrer: document.referrer || "",
      });
      submitForm(body);
    }
  };

  const validate = () => {
    let errorFound = false;
    const errors: FormErrors = {
      debitAmount: false,
      firstName: false,
      lastName: false,
      phone: false,
      email: false,
    };

    if (debitAmount === "") {
      errors.debitAmount = true;
      errorFound = true;
    }

    if (firstName === "") {
      errors.firstName = true;
      errorFound = true;
    }

    if (lastName === "") {
      errors.lastName = true;
      errorFound = true;
    }

    if (!validator.isMobilePhone(phone, "en-US")) {
      errors.phone = true;
      errorFound = true;
    }

    if (!validator.isEmail(email)) {
      errors.email = true;
      errorFound = true;
    }

    setFormErrors(errors);

    return !errorFound;
  };

  return (
    <React.Fragment>
      <Container
        maxWidth={false}
        disableGutters
        style={{ backgroundColor: "#ffffff" }}
      >
        <Header
          phoneNumber={defaultData.phoneNumber}
          showPhone={true}
          showWhyChooseUs={true}
          showFaqs={true}
          showCheckRates={true}
        />
        <DoYouQualify
          debitAmount={debitAmount}
          firstName={firstName}
          lastName={lastName}
          phone={phone}
          email={email}
          setDebitAmount={setDebitAmount}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setPhone={setPhone}
          setEmail={setEmail}
          handleSubmit={handleSubmit}
          formErrors={formErrors}
        />
        <AccreditedBar />
        <WhatIsDebtConsolidation />
        <HowItWorks />
        <IsDebtConsolodationRightForMe />
        <Benefits />
        <Footer
          addressLine1={defaultData.addressLine1}
          addressLine2={defaultData.addressLine2}
          copyright={defaultData.copyright}
          email={defaultData.email}
        />
      </Container>
    </React.Fragment>
  );
};

export default Homepage;

import React from "react";
import { Typography } from "@material-ui/core";
import { Container } from "@material-ui/core";

const NotFound: React.FC = () => (
  <React.Fragment>
    <Container
      maxWidth={false}
      disableGutters
      style={{ backgroundColor: "#ffffff" }}
    >
      <Typography variant="h4">File not found</Typography>
      <Typography variant="body1">
        The requested file could not be found.
      </Typography>
    </Container>
  </React.Fragment>
);

export default NotFound;

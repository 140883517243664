import React, { useContext, ChangeEvent } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { ClassesContext } from "./StylesProvider";
import FormInput from "./FormInput";
import CCPAFormButton from "./CCPAFormButton";
import { FormErrors } from "../pages/CCPARequestForm";
import AppSpinner from "./AppSpinner";

import { Checkbox, FormControlLabel } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  responsiveWrapper: {
    flex: "1",
  },
  bodyWrapper: {
    alignSelf: "center",
    width: "100%",
  },
  form: {
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      paddingTop: "21px",
    },
    [theme.breakpoints.only("md")]: {
      borderRadius: "8px",
      border: "solid 3px #ffffff",
    },
    [theme.breakpoints.up("lg")]: {
      borderRadius: "8px",
      border: "solid 3px #ffffff",
    },
  },
  formInput: {
    borderRadius: "4px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "12px",

      paddingRight: "17px",
    },
    [theme.breakpoints.only("md")]: {
      marginTop: "15px",

      height: "39px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "21px",

      height: "50px",
    },
  },
  body: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  container: {
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "60px",
      padding: "40px",
      maxWidth: "1170px",
    },
  },
  subHeader: {
    fontFamily: "OpenSans, Sans-serif",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginTop: "31px",
      fontSize: "22px",
    },
    [theme.breakpoints.only("md")]: {
      textAlign: "left",
      marginTop: "29px",
      fontSize: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      textAlign: "left",
      fontSize: "40px",
    },
  },
  subCopy: {
    marginTop: "34px",
    fontFamily: "ProximaNova, Sans-serif",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "justify",
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "24px",
    },
  },
  faqsList: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "35px",
    },
    [theme.breakpoints.only("md")]: {
      marginTop: "24px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "66px",
    },
  },
  heading: {
    marginLeft: "10px",
    fontFamily: "ProximaNova, Sans-serif",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "justify",
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "21px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "21px",
    },
  },
  description: {
    fontFamily: "ProximaNova, Sans-serif",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "justify",
    color: "#000000",
    marginTop: "10px",
    marginLeft: "30px",
    marginRight: "30px",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "18px",
    },
  },
  panelSummary: {
    flexDirection: "row-reverse",
    borderBottom: ".5px solid #000",
    paddingLeft: "0px",
  },
  numberIndent: {
    paddingRight: "30px",
  },
  pIndent: {
    textIndent: "30pt",
  },
  secondIndent: {
    textIndent: "60pt",
  },
  thirdIndent: {
    textIndent: "90pt",
  },
  centerAlign: {
    textAlign: "center",
  },
  tableIndent: {
    paddingLeft: "30px",
  },
  rowPadding: {
    paddingBottom: "20px",
  },
  detailTextArea: {
    width: "100%",
  },
  textDetails: {
    width: "100%",
    height: "130px",
    resize: "vertical",
    padding: "25px 15px",
    background: "#fff",
    fontSize: "16px",
  },
}));

type Props = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  addressState: string;
  dob: string;
  zipcode: string;
  details: string;
  checkedDelete: string;
  checkedDelivery: string;
  checkedContactPref: string;
  checkedExclude: string;
  checkedSendPersonal: string;
  sentSuccess: boolean;
  showForm: boolean;
  showLoading: boolean;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setPhone: (phone: string) => void;
  setEmail: (email: string) => void;
  setAddress: (address: string) => void;
  setCity: (city: string) => void;
  setStateAddress: (state: string) => void;
  setZipcode: (zipcode: string) => void;
  setDOB: (address: string) => void;
  setSentSuccess: (setSentSuccess: boolean) => void;
  setCheckedDelete: (checkedDelete: string) => void;
  setCheckedContactPref: (checkedContactPref: string) => void;
  setCheckedDelivery: (checkedDelivery: string) => void;
  setCheckedExclude: (checkedExclude: string) => void;
  setCheckedSendPersonal: (checkedSendPersonal: string) => void;
  setShowForm: (showForm: boolean) => void;
  setAddDetails: (details: string) => void;
  formErrors: FormErrors;
  handleSubmit: () => void;
  setShowLoading: (showLoading: boolean) => void;
};

const CCPARequestFormPage = ({
  firstName,
  lastName,
  phone,
  email,
  address,
  city,
  addressState,
  dob,
  zipcode,
  details,
  sentSuccess,
  setSentSuccess,
  showForm,
  showLoading,
  setFirstName,
  setLastName,
  setAddress,
  setPhone,
  setEmail,
  setCity,
  setStateAddress,
  setDOB,
  setZipcode,
  setAddDetails,
  setCheckedDelete,
  setCheckedContactPref,
  setCheckedDelivery,
  setCheckedExclude,
  setCheckedSendPersonal,
  setShowForm,
  setShowLoading,
  handleSubmit,
  formErrors,
}: Props) => {
  const classes = useStyles();
  const themedClasses = useContext(ClassesContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.responsiveWrapper}>
      <div className={classes.bodyWrapper}>
        <div className={classes.body}>
          <div className={classes.container}>
            <h1 className="entry-title">
              Secrets to Credit’s CCPA Consumer Request Webpage{" "}
            </h1>
            <div className="entry-content">
              <p>
                This Webpage applies solely to those who are consumers (“you” or
                “your”) as defined in Section 1798.140(g) of the California
                Consumer Privacy Act of 2018 (“CCPA”) and any terms defined in
                the CCPA have the same meaning when used in this Webpage.
                Secrets to Credit, LLC (“Company,” “we,” or “our”) provides this
                Webpage to outline the rights that the CCPA gives the consumer
                to access and request deletion of the personal information that
                a business holds about you and to give you a means to easily
                exercise those rights.{" "}
              </p>

              <p>
                You are entitled to be informed whether your personal
                information is collected, used, stored, and/or disclosed by us.
                You may request, up to twice per 12-month period, to be sent a
                copy of your personal information that we retain in our files,
                subject to any applicable exemptions.
              </p>

              <p>
                In addition, you have the right to request that we delete your
                personal information from our records, subject to any applicable
                exemptions. For example, we may retain personal information that
                is needed to provide you with a requested service, prevent
                fraud, or to meet our legal obligations (additional exemptions
                are listed in our CA Privacy Notice. Be advised that we are not
                able to delete your personal information from any third party’s
                records unless that third party is a service provider to us.
              </p>
              <p>
                To request access to and/or deletion of your personal
                information from our systems, please complete the form at the
                bottom of this page or call us at 800-611-5155. Upon receipt of
                your request, we may ask you for additional information to
                verify your identity and the validity of your request. (If you
                have multiple requests, please fill out a separate form for each
                request.)
              </p>
              <p>
                While we strive to appropriately answer all requests within 10
                days of receiving the request, we have up to forty-five (45)
                days to respond. If we need additional time, we may also inform
                you that we need up to forty-five (45) additional days to
                respond and the reason(s) for the additional time.
              </p>
              <p>
                Before submitting your request, we ask that you review our
                Online Privacy Policy and CA Privacy Notice. The Privacy Policy
                and CA Privacy Notice provide valuable information about how we
                collect, use, store, and disclose personal information. You may
                find the answer you are looking for faster by reviewing these
                documents than by submitting a request and waiting for our
                response.
              </p>
              {sentSuccess && (
                <div
                  style={{
                    backgroundColor: "#b4ffb4",
                    border: "1px solid #008002",
                    marginTop: "60px",
                    fontSize: "18px",
                    fontWeight: 800,
                    textAlign: "center",
                    color: "#008002",
                  }}
                >
                  Thank you, your request has been submitted.
                </div>
              )}

              {showForm && (
                <div className={classes.form}>
                  {showLoading && <AppSpinner />}
                  <p>
                    <strong>REQUEST FORM</strong>
                  </p>

                  <FormInput
                    handleSubmit={handleSubmit}
                    className={classes.formInput}
                    id="first-name"
                    label="First Name"
                    value={firstName}
                    setValue={setFirstName}
                    error={formErrors.firstName}
                  />
                  <FormInput
                    handleSubmit={handleSubmit}
                    className={classes.formInput}
                    id="last-name"
                    label="Last Name"
                    value={lastName}
                    setValue={setLastName}
                    error={formErrors.lastName}
                  />
                  <FormInput
                    handleSubmit={handleSubmit}
                    className={classes.formInput}
                    id="email"
                    label="Email Address"
                    value={email}
                    setValue={setEmail}
                    error={formErrors.email}
                  />
                  <FormInput
                    handleSubmit={handleSubmit}
                    className={classes.formInput}
                    id="phone-number"
                    label="Phone Number"
                    value={phone}
                    setValue={setPhone}
                    mask={"(999) 999-9999"}
                    error={formErrors.phone}
                  />
                  <FormInput
                    handleSubmit={handleSubmit}
                    className={classes.formInput}
                    id="address"
                    label="Address"
                    value={address}
                    setValue={setAddress}
                    error={formErrors.address}
                  />
                  <FormInput
                    handleSubmit={handleSubmit}
                    className={classes.formInput}
                    id="city"
                    label="City"
                    value={city}
                    setValue={setCity}
                    error={formErrors.city}
                  />
                  <FormInput
                    handleSubmit={handleSubmit}
                    className={classes.formInput}
                    id="state"
                    label="State"
                    value={addressState}
                    setValue={setStateAddress}
                    error={formErrors.addressState}
                  />

                  <FormInput
                    handleSubmit={handleSubmit}
                    className={classes.formInput}
                    id="zipcode"
                    label="Zip Code"
                    value={zipcode}
                    setValue={setZipcode}
                    error={formErrors.zipcode}
                  />
                  <FormInput
                    handleSubmit={handleSubmit}
                    className={classes.formInput}
                    id="dob"
                    label="Year of Birth"
                    value={dob}
                    setValue={setDOB}
                    error={formErrors.dob}
                  />
                  <p>
                    <strong>
                      1. You may select between mail and email delivery.{" "}
                    </strong>
                  </p>

                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setCheckedDelivery(e.target.name)}
                        name="Mail"
                      />
                    }
                    label="Mail"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setCheckedDelivery(e.target.name);
                        }}
                        name="Email"
                      />
                    }
                    label="Email"
                  />

                  <p>
                    <strong>
                      2. Would you like us to send you personal information that
                      we have collected on you?{" "}
                    </strong>
                  </p>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setCheckedSendPersonal(e.target.name);
                        }}
                        name="Yes"
                      />
                    }
                    label="Yes"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setCheckedSendPersonal(e.target.name);
                        }}
                        name="No"
                      />
                    }
                    label="No"
                  />

                  <p>
                    <strong>
                      3. If we have collected your personal information, would
                      you like us to exclude sensitive personal information
                      (e.g., social security number, date of birth, and
                      financial account information) from what we send you?{" "}
                    </strong>
                  </p>

                  <p>
                    <strong>
                      If you choose “yes,” we will still provide you with the
                      categories of sensitive information we have on file, but
                      not the content of the information. For example, we may
                      say that we have your social security number on file, but
                      we will not disclose the actual social security number.
                    </strong>
                  </p>

                  <p>
                    <strong>
                      If you choose “no,” we may require more thorough identity
                      verification to protect the security of the information
                      and we may still apply limited redactions.
                    </strong>
                  </p>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setCheckedExclude(e.target.name);
                        }}
                        name="Yes"
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setCheckedExclude(e.target.name);
                        }}
                        name="No"
                      />
                    }
                    label="No"
                  />
                  <br />
                  <p>
                    <strong>Add Details:</strong>
                  </p>
                  <textarea
                    className={classes.textDetails}
                    name="details"
                    placeholder=""
                    style={{ opacity: 1 }}
                    value={details}
                    onChange={(e) => setAddDetails(e.target.value)}
                  ></textarea>
                  <p>
                    <strong>
                      4. Would you like us to delete your personal information
                      (subject to applicable exemptions)?{" "}
                    </strong>
                  </p>

                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setCheckedDelete(e.target.name);
                        }}
                        name="Yes"
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setCheckedDelete(e.target.name);
                        }}
                        name="No"
                      />
                    }
                    label="No"
                  />

                  <p>
                    <strong>
                      5. What are your preferred contact methods for questions
                      about your request?{" "}
                    </strong>
                  </p>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setCheckedContactPref(e.target.name);
                        }}
                        name="Yes"
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setCheckedContactPref(e.target.name);
                        }}
                        name="No"
                      />
                    }
                    label="No"
                  />

                  <p>
                    <strong>
                      By submitting this form, I certify that I am the
                      above-named individual or a person authorized by the
                      above-named individual to submit this form on their
                      behalf. I understand that Secrets to Credit, LLC may
                      contact me to obtain identity verification and validate
                      this request.
                    </strong>
                  </p>

                  <CCPAFormButton handleSubmit={handleSubmit} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CCPARequestFormPage;

import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import bbb from '../static/home/bbb.png';
import mcafee from '../static/home/mcafee.png';
import norton from '../static/home/norton.png';

const useStyles = makeStyles(theme => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#ffffff',
        },
        [theme.breakpoints.only('md')]: {
            backgroundColor: '#ffffff',
        },
        [theme.breakpoints.up('lg')]: {
            backgroundColor: '#ffffff',
        },
    },
    body: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
        },
        [theme.breakpoints.only('md')]: {
            padding: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '40px',
            maxWidth: '541px',
        },
    },
    logoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        
        [theme.breakpoints.down('sm')]: {
            width:'40%'
        },
        [theme.breakpoints.only('md')]: {
            width: '40%'
        },
        [theme.breakpoints.up('lg')]: {
            width:'50%'
        },
    },
    logo: {
        [theme.breakpoints.down('sm')]: {
            height: "44px"
        },
        [theme.breakpoints.only('md')]: {
            height: "51px"
        },
        [theme.breakpoints.up('lg')]: {
            height: "73px"
        },
    },
    bbbLogo: {
        [theme.breakpoints.down('sm')]: {
            height: "32px"
        },
        [theme.breakpoints.only('md')]: {
            height: "51px"
        },
        [theme.breakpoints.up('lg')]: {
            height: "73px"
        },
    },
}));



const AccreditedBar = () => {
    const classes = useStyles();

    return <div className={classes.responsiveWrapper}>
        <div className={classes.body}>
            <div className={classes.logoWrapper}>
                
            <div className="trustedsite-trustmark" data-type="202" data-width="180"  data-height="75"></div>
            </div>
            <div className={classes.logoWrapper}>
            <div className="mfes-trustmark" data-type="102" data-width="180" data-height="75"></div>
                {/* <div className="mfes-trustmark" data-type="102" data-width="120" data-height="50"></div> */}
            </div>
            
         
        </div >
    </div >
}

export default AccreditedBar;
import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import circlePercent from '../static/home/low_rates_icon.png';
import quickEasyIcon from '../static/home/quick_easy_icon.png';
import circleRocketShip from '../static/home/pay_off_faster_icon.png';

const useStyles = makeStyles(theme => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: '#064169',
    },
    body: {
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
        },
        [theme.breakpoints.only('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '1170px',
            width: '95%',
        },
    },
    header: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#ffffff',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginTop: '40px',
            fontSize: '22px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '29.5px',
            marginTop: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '50px',
            fontSize: '50px',
        },
    },
    circlesWrapper: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
            flexDirection: 'column',
        },
        [theme.breakpoints.only('md')]: {
            justifyContent: "center",
            flexDirection: 'row',
        },
        [theme.breakpoints.up('lg')]: {
            justifyContent: "center",
            flexDirection: 'row',
            marginBottom: '67px',
        },
    },
    circleGroup: {
        display: 'flex',
        flexDirection: 'column',
    },
    circles: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: '42px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '34px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '59px',
        },
    },
    circleImage: {
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: '34px',
            width: '76px',
            height: '76px',
        },
        [theme.breakpoints.only('md')]: {
            width: '75px',
            height: '75px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '126px',
            height: '126px',
        },
    },
    circleHeader: {
        alignSelf: 'center',
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            marginTop: '15px',
            fontSize: '18px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '15px',
            fontSize: '18px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '25px',
            height: '23px',
            fontSize: '32px',
        },
    },
    circleText: {
        alignSelf: 'center',
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#ffffff',
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            marginTop: '11px',
            fontSize: '16px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '9px',
            fontSize: '11px',
            marginBottom: '33px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '29px',
            fontSize: '18px',
        },
    },
}));

const HowItWorks = () => {
    const classes = useStyles();

    return <div className={classes.responsiveWrapper}>
        <div className={classes.body}>
            <div className={classes.header}>Benefits of Consolidation</div>
            <div className={classes.circlesWrapper + ' ' + classes.circles}>
                <div className={classes.circleGroup}>
                    <img src={circlePercent} className={classes.circleImage} alt="Percent in a circle" />
                    <div className={classes.circleHeader}>
                        Low Rates
                </div>
                    <div className={classes.circleText}>
                        With lower interest rates, you can pay off debt without compounding interest.
                </div>
                </div>
                <div className={classes.circleGroup}>
                    <img src={quickEasyIcon} className={classes.circleImage} alt="Fuel Guage" />
                    <div className={classes.circleHeader}>
                        Quick <span>&#38;</span> Easy
                </div>
                    <div className={classes.circleText}>
                    Your no-obligation consultation only takes a few minutes and could potentially save you a lot of money.
                </div>
                </div>
                <div className={classes.circleGroup}>
                    <img src={circleRocketShip} className={classes.circleImage} alt="Rocket Ship" />
                    <div className={classes.circleHeader}>
                        Pay Off Faster
                </div>
                    <div className={classes.circleText}>
                        Choosing a fixed rate makes payments easy to track—and gives a target payoff date.
                </div>
                </div>
            </div>
        </div >
    </div >
}

export default HowItWorks;
import React, { useContext, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import Header from '../static/landing/landing_header.png';
import Slider from './MySlider';
import CheckYourRate from './CheckYourRate';
import { useHistory } from "react-router-dom";
import { ClassesContext } from './StylesProvider';

const useStyles = makeStyles(theme => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    imageWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        backgroundColor: '#374859',
        width: '100%',
    },
    headerImage: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
    body: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'start',
        backgroundImage: 'url(' + Header + ')',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            backgroundImage: 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(' + Header + ')',
            backgroundSize: '800px',
            minHeight: '283px',
            height: '365',
        },
        [theme.breakpoints.only('md')]: {
            maxHeight: '419px',
            height: '820px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '662px',
            maxWidth: '1690px',
        },
    },
    header: {
        width: '100%',
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            marginTop: '25px',
            fontSize: '26px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '40px',
            fontSize: '41.5px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '84px',
            fontSize: '66px',
        },
    },
    tagLine: {
        width: '100%',
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            marginTop: '14px',
            fontSize: '18px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '22px',
            fontSize: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '34px',
            fontSize: '32px',
        },
    },
    sliderWrapper: {
        marginTop: '62px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '22px',
        },
        [theme.breakpoints.only('md')]: {
            marginBottom: '22px',
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '22px',
        },
    },
    slider: {
    },
}));

const HowMuchDoYouNeed = () => {
    const classes = useStyles();
    const history = useHistory();
    const themedClasses = useContext(ClassesContext);
    const [debitAmount, setDebitAmount] = useState(1000);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        history.push("/apply/" + debitAmount);
    }

    return <div className={classes.responsiveWrapper}>
        <div className={classes.imageWrapper}>
            <div className={classes.headerImage}>
                <div className={themedClasses.homeBackgroundImage}>
                    <Typography className={classes.header}>How Much Do You Need?</Typography>
                    <Typography className={classes.tagLine}>Loans from $1,000 to $100,000+</Typography>
                    <div className={classes.sliderWrapper}>
                        <Slider
                            debitAmount={debitAmount}
                            setDebitAmount={setDebitAmount}
                            className={classes.slider}
                        />
                    </div>

                    <CheckYourRate
                        handleClick={handleClick}
                    />
                </div>
            </div>
        </div>
    </div>
}

export default HowMuchDoYouNeed;
import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StylesContext } from "../components/StylesProvider";
import { Container } from "@material-ui/core";
import Header from "../components/Header";
import Footer from "../components/Footer";
import defaultData from "../defaultData.js";
import OurClientCommitment from "../components/OurClientCommitment";
import Congratulations from "../components/Congratulations";
import { useLocation } from "react-router-dom";

export type stateType = {
  firstName: string;
};

const ThankYou = ({ match }: RouteComponentProps<any>) => {
  const setThemeName = useContext(StylesContext);
  setThemeName("thankYou");
  const { state } = useLocation<stateType>();

  return (
    <React.Fragment>
      <Container
        maxWidth={false}
        disableGutters
        style={{ backgroundColor: "#ffffff" }}
      >
        <Header
          phoneNumber={defaultData.phoneNumber}
          showPhone={true}
          showWhyChooseUs={true}
          showFaqs={true}
          showCheckRates={true}
        />
        <Congratulations
          phone={"1-" + defaultData.phoneNumber}
          firstName={state ? state.firstName : ""}
        />
        <OurClientCommitment />
        <Footer
          addressLine1={defaultData.addressLine1}
          addressLine2={defaultData.addressLine2}
          copyright={defaultData.copyright}
          email={defaultData.email}
        />
      </Container>
    </React.Fragment>
  );
};

export default ThankYou;

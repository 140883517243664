import React from "react";

const AppSpinner = () => {
    return (
      <div id="nbspinneroverlay">
        <div className="nbspinner" />
      </div>
    );
  }


export default AppSpinner;
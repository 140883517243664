import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button, withStyles } from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: '4px',
        backgroundColor: '#f27e20',
        [theme.breakpoints.down('sm')]: {
            marginTop: '22px',
            marginRight: '17px',
        },
        [theme.breakpoints.only('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
            width: '300px',
            height: '80px',
        },
    },
}));

const StyledButton = withStyles(theme => ({
    root: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            fontSize: '21px',
            lineHeight: '1.52',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '12px',
            lineHeight: '1.14',
            width: '191px',
            height: '52px',
            marginRight: '42px',
            marginLeft: '55px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '18px',
            whiteSpace:'nowrap',
            lineHeight: '0.93',
            marginLeft: '87px',
        },
    },
}))(Button);

type Props = {
    label?: string,
}

const ApplyNowButton = ({ label }: Props) => {
    const classes = useStyles();
    const history = useHistory();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        history.push("/apply/");
    }

    return <StyledButton
        className={classes.button}
        variant="contained"
        onClick={handleClick}
    >
        CHECK YOUR RATE
    </StyledButton>
}

export default ApplyNowButton
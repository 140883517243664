import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import checkmark from '../static/home/checkmark.png';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#f1f4f4',
        },
        [theme.breakpoints.only('md')]: {
            backgroundColor: '#f6f6f6',
        },
        [theme.breakpoints.up('lg')]: {
            backgroundColor: '#f6f6f6',
        },
    },
    body: {
        maxWidth: '100%',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
            paddingLeft: '17px',
            paddingRight: '0px',
            paddingTop: '27px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '30px',
            paddingLeft: '53px',
            paddingRight: '23px',
            paddingTop: '85px',
            paddingBottom: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '50px',
            maxWidth: '1170px',
            width: '95%',
            paddingLeft: '45px',
            paddingRight: '25px',
            paddingTop: '85px',
            paddingBottom: '80px',
        },
    },
    header: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2b6587',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '30px',
            marginBottom: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '50px',
            marginBottom: '64px',
        },
    },
    header2: {
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2b6587',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '50px',
        },
    },
    bulletWrapper: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '15px',
    },
    bulletCheckmark: {
        alignSelf: 'center',
        width: '31px',
        height: '30px',
    },
    bulletText: {
        alignSelf: 'center',
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 600,
        textAlign: 'left',
        width: '100%',
        color: '#585859',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '15px',
        },
        [theme.breakpoints.only('md')]: {
            paddingLeft: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '24px',
        },
    },
    columnWrapper: {
        display: 'flex',
        justifyContent: "start",
        flexDirection: 'column',
    },
    rowWrapper: {
        display: 'flex',
        justifyContent: "space-between",
        flexDirection: 'row',
    },
    bottomWrapper: {
        marginTop: '5px',
        display: 'flex',
        justifyContent: "space-between",
    },
    checkboxesWrapper: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#585859',
        marginTop: '28px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '32px',
            paddingRight: '17px',
            fontSize: '16px',
            lineHeight: '1.13',
        },
        [theme.breakpoints.only('md')]: {
            marginRight: '30px',
            fontSize: '15px',
            lineHeight: '1.37',
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: '30px',
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    middleText: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#5f5e5e',
        [theme.breakpoints.down('sm')]: {
            marginTop: '24px',
            marginBottom: '26px',
            paddingRight: '17px',
            fontSize: '16px',
            lineHeight: '1.06',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '15px',
            lineHeight: '1.53',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    middleTextSection2: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#5f5e5e',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '17px',
            fontSize: '16px',
            lineHeight: '1.06',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '42px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '42px',
        },
    },
    conclusion: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#5f5e5e',
        [theme.breakpoints.down('sm')]: {
            marginRight: '17px',
            fontSize: '16px',
            lineHeight: '1.13',
        },
        [theme.breakpoints.only('md')]: {
            width: '456px',
            fontSize: '15px',
            lineHeight: '1.37',
        },
        [theme.breakpoints.up('lg')]: {
            width: '785px',
            height: '117px',
            fontSize: '24px',
            lineHeight: '1.33',
        },
    },
    middlePic: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '23px',
            width: '100%',
            maxWidth: '330px',
        },
        [theme.breakpoints.only('md')]: {
            paddingLeft: '45px',
            width: '50%',
            float: 'right',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '45px',
            width: '50%',
            float: 'right',
        },
    },
    horizontalLine: {
        marginTop: '34px',
        height: '3px',
        width: '100%',
    },
    verticalLine: {
        borderLeft: '1px solid black',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '23px',
            width: '100%',
            maxWidth: '330px',
        },
        [theme.breakpoints.only('md')]: {
            marginRight: '28px',
            marginLeft: '28px',
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: '80px',
            marginLeft: '80px',
        },
    },
}));

const LookingForDebtConsolidationLoan = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (isMobile) {
        return <div className={classes.responsiveWrapper}>
            <div className={classes.body}>
                <div className={classes.header} style={{ width: '60%' }}>Looking for a Debt Consolidation Loan?</div>
                <div className={classes.middleText}>
                    Debt is a widespread issue in the United States today. Many Americans struggle to manage multiple credit accounts with separate payments, due dates, and interest rates. Debt consolidation is a well-known and trusted solution for consumers looking to get out of debt quickly and for the least amount possible.
                </div>
                <div className={classes.middleTextSection2}>
                    Debt consolidation loans allow you to bring multiple credit accounts together under a new single account. This single account should have one payment amount and a competitive interest rate, compared to multiple separate payments and interest rates before consolidating. It’s vital that consumers in debt don’t miss payments, so one account versus several helps keep things organized and ensure no payments are missed. And with a new, more competitive interest rate, you may be able to save money in the repayment process.
                </div>
                <div className={classes.checkboxesWrapper}>
                    <div className={classes.bulletWrapper}>
                        <div className={classes.header2}>Possible Benefits</div>
                    </div>
                    <div className={classes.bulletWrapper}>
                        <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                        <span className={classes.bulletText}>
                            Lower APR
                        </span>
                    </div>
                    <div className={classes.bulletWrapper}>
                        <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                        <span className={classes.bulletText}>
                            Fewer bills to track
                        </span>
                    </div>
                    <div className={classes.bulletWrapper}>
                        <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                        <span className={classes.bulletText}>
                            Faster debt repayment
                        </span>
                    </div>
                   
                    <div className={classes.bulletWrapper}>
                        <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                        <span className={classes.bulletText}>
                            Serious Savings
                        </span>
                    </div>
                </div>
            </div >
        </div>
    }

    return <div className={classes.responsiveWrapper}>
        <div className={classes.body}>
            <div className={classes.rowWrapper} style={{ width: '55%' }}>
                <div className={classes.header}>Looking for a Debt Consolidation Loan?</div>
            </div>
            <div className={classes.rowWrapper}>
                <div className={classes.columnWrapper} style={{ width: '55%' }}>
                    <div className={classes.middleText}>
                        <Typography>
                            Debt is a widespread issue in the United States today. Many Americans struggle to manage multiple credit accounts with separate payments, due dates, and interest rates. Debt consolidation is a well-known and trusted solution for consumers looking to get out of debt quickly and for the least amount possible.
                        </Typography>
                        <Typography className={classes.middleTextSection2}>
                            Debt consolidation loans allow you to bring multiple credit accounts together under a new single account. This single account should have one payment amount and a competitive interest rate, compared to multiple separate payments and interest rates before consolidating. It’s vital that consumers in debt don’t miss payments, so one account versus several helps keep things organized and ensure no payments are missed. And with a new, more competitive interest rate, you may be able to save money in the repayment process.
                        </Typography>
                    </div>
                </div>
                <div>
                    <div className={classes.verticalLine}></div>
                </div>
                <div className={classes.columnWrapper}>
                    <div className={classes.header2}>Possible Benefits</div>
                    <div className={classes.checkboxesWrapper}>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Lower APR
                            </span>
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Fewer bills to track
                            </span>
                        </div>
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Faster debt repayment
                            </span>
                        </div>
                  
                        <div className={classes.bulletWrapper}>
                            <img src={checkmark} className={classes.bulletCheckmark} alt="Checkmark." />
                            <span className={classes.bulletText}>
                                Serious Savings
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </div >
}

export default LookingForDebtConsolidationLoan;
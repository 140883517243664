import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import BarChart from './BarChart';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        paddingBottom:'110px',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#f6f6f6',
        },
        [theme.breakpoints.only('md')]: {
            backgroundColor: '#f6f6f6',
        },
        [theme.breakpoints.up('lg')]: {
            backgroundColor: '#f6f6f6',
        },
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '38px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '50px',
            maxWidth: '1170px',
            marginLeft: '16px',
            marginRight: '16px',
            },
    },
    headerWrapper: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.only('md')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'start',
        },
    },
    header: {
        fontFamily: 'OpenSans, Sans-serif',
        lineHeight: 'normal',
        color: '#2b6587',
        [theme.breakpoints.down('sm')]: {
            marginTop: '40px',
            fontSize: '20px',
            textAlign: 'center',
            maxWidth: '90%',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '38px',
            paddingLeft: '53px',
            paddingRight: '23px',
            fontSize: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '61px',
            fontSize: '50px',
        },
    },
    headerCopy: {
        fontFamily: 'ProximaNova, Sans-serif',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: 'normal',
        color: '#5f5e5e',
        alignSelf: 'center',
        textAlign: 'justify',
        textJustify: 'inter-word',
        [theme.breakpoints.down('sm')]: {
            marginTop: '19px',
            marginLeft: '16px',
            marginRight: '16px',
            fontSize: '16px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '15px',
            marginLeft: '52px',
            marginRight: '88px',
            fontSize: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '31px',
            fontSize: '24px',
        },
    },
    chartContainer: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            marginTop: '27px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '45px',
            marginBottom: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '45px',
            marginBottom: '20px',
        },
    },
    chartHeader: {
        alignSelf: 'center',
        fontFamily: 'OpenSans, Sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1',
        letterSpacing: 'normal',
        color: '#2b6587',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '10px',
        marginRight: '10px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
            fontSize: '16px',
            textAlign: 'center',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '23px',
            width: '95%',
            fontSize: '18px',
            textAlign: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '46px',
            width: '90%',
            fontSize: '32px',
            textAlign: 'center',
        },
    },
    chartTitle: {

    },
    chartTitleBar: {

    },
    chart: {
        display: 'flex',
        justifyContent: 'center',
    },
    chartDisclaimer:{
        display: 'flex', 
        margin: 'auto',
        fontSize: '14px',
        paddingLeft: '10px',
        paddingRight: '10px',
        
        [theme.breakpoints.down('sm')]: {
            fontSize: '11px',
            paddingBottom: '20px',
        },
        [theme.breakpoints.only('md')]: {
            width: '95%',
            fontSize: '11px',
            paddingBottom: '23px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '90%',
            paddingBottom: '46px',
        },

    }
}));

const IsDebtConsolodationRightForMe = () => {
    const classes = useStyles();

    return (
        <div className={classes.responsiveWrapper}>
            <div className={classes.body}>
                <div className={classes.headerWrapper}>
                    <Typography className={classes.header}>Is Debt Consolidation Right for Me?</Typography>
                </div >
                <div className={classes.headerCopy}>
                    Under this type of plan, it's even more critical that consumers stay up to date with payments. It's also important to remember that loans do not make debt disappear, they simply move the debt to another institution. This solution is helpful if you have multiple debts at multiple sources and if the interest rates on those accounts are high. A debt consolidation loan would pull all those multiple accounts into one with one easier to manage interest rate - making the process of paying that money back more manageable.
                </div >
                <div className={classes.chartContainer}>
                    <div className={classes.chartHeader}>
                        A Debt Consolidation Loan Can Help You Pay Off Debt In Months
                    </div >
                    <div className={classes.chart}>
                        <BarChart
                            viewportWidth='100%'
                            viewportHeight='100%'
                            bars={[
                                {
                                    value: 1431,
                                    breakpoint: 10000,
                                },
                                {
                                    value: 1145,
                                    breakpoint: 8000,
                                },
                                {
                                    value: 715,
                                    breakpoint: 5000,
                                },
                            ]}
                        />
                   
                    </div>
                    <Typography className={classes.chartDisclaimer}>Examples for illustrative purposes only. Estimations were determined through Bankrate.com’s loan consolidation calculator with minimum credit card payments of 4% of existing outstanding balances at 21% APR versus a 5-year loan at 6% APR.</Typography>
                </div >
            </div >
        </div >
    )
}

export default IsDebtConsolodationRightForMe;
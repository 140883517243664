import React, { useContext } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ClassesContext } from './StylesProvider';

const useStyles = makeStyles(theme => ({
    responsiveWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        backgroundColor: '#616161',
        width: '100%',
    },
    imageWrapper: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
}));

const WhyUseDebtConsolidation = () => {
    const classes = useStyles();
    const themedClasses = useContext(ClassesContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return <div className={classes.responsiveWrapper}>
        <div className={classes.imageWrapper}>
            <div className={themedClasses.homeBackgroundImage}>
                <Typography className={themedClasses.formHeadline}>Why Use Debt {isMobile ? <br></br> : ''}Consolidation Loans?</Typography>
            </div>
        </div>
    </div>
}

export default WhyUseDebtConsolidation;